<template>
  <div class="flex items-center text-lightblue add-minus">
    <span class="pointer sp weight text-center" @click="minusHandle">-</span>
    <span class="text-center flex-one text">{{ text }}</span>
    <span class="pointer sp weight text-center" @click="addHandle">+</span>
  </div>
</template>

<script>
export default {
  name: 'AddMinus',
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    minusHandle() {
      this.$emit('minus');
    },
    addHandle() {
      this.$emit('add');
    },
  },
};
</script>

<style scoped>
.add-minus {
  height: 24px;
  line-height: 24px;
}
.add-minus .sp {
  font-size: 18px;
  background: rgba(42, 80, 132, 0.5);
  width: 30px;
}
.add-minus .text {
  background: rgba(59, 124, 202, 0.5);
}
</style>
