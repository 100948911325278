<template>
  <div>
    <div
      class="video-dialog-group flex items-center justify-center"
      ref="wrap"
      v-show="visible"
    >
      <div class="vd-inner relative" ref="vdInner">
        <div class="vd-i-hd flex items-center justify-between">
          <span class="flex-one flex items-center">
            <span
              class="dot online-dot recording-animate m-r-xs"
              v-show="false"
            ></span>
            <span
              class="wd40 ellipsis pointer"
              @mousedown.stop.prevent
              @click.stop.prevent="
                hasPermission(1092101210) && (showDetail = !showDetail)
              "
              :title="currentCameraNameStr"
              >{{ currentCameraNameStr }}</span
            >
            <!--  { current: currentCamera.hasFavorited }, -->
            <i
              :class="['icon-c icon-collect pointer m-l-xs']"
              title="收藏/取消"
              @mousedown.stop.prevent
              @click.stop.prevent="addCollectItemHandle()"
            ></i>
          </span>
          <span
            class="text-lightblue pointer f20 weight block bg-white p-w-sm relative"
            @click.stop="closeHandle($event)"
            @mousedown.stop.prevent
            style="background: rgba(84, 119, 183, 0.1); z-index: 999"
            >×</span
          >
        </div>
        <div class="vd-i-content full-height relative">
          <img
            src="~@/assets/video-rec.gif"
            class="absolute cloudZindex l20 t20"
            v-show="showDepartStop || showStop"
          />

          <span
            v-if="isPlatformDisplay"
            title="云台控制"
            class="absolute icon-bar pointer cloudZindex"
            @click="toggleCloud()"
          ></span>
          <cameral-detail
            :cameraInfo="cameraInfo"
            class="absolute c-d-i"
            v-show="showDetail"
          />
          <flv-player
            ref="flvPlay"
            v-if="usePlayerType === 'flv' && !isThreePlayer && !isAliPlayer"
            video-type="flv"
            @getBase64="getBase64Handle"
            :control="false"
          ></flv-player>
          <three-player
            ref="threePlay"
            v-if="usePlayerType === 'flv' && isThreePlayer && !isAliPlayer"
            video-type="flv"
          ></three-player>
          <canvas id="canvas" width="640" height="360"></canvas>
        </div>
        <div class="vd-i-footer flex items-center justify-between">
          <div class="flex items-center">
            <!-- v-hasPermiss="'000'" -->
            <i
              title="手动抓拍"
              class="pointer icon-a icon-cut m-r-sm"
              @click="imgCut()"
            ></i>
            <!--  <i
              class="pointer icon-a icon-stop m-r-sm"
              v-show="showStop"
              @click="recordingHandle()"
            ></i> -->
            <span
              class="text-lightblue pointer m-r-sm flex items-center justify-center"
              @click="projectionHandle"
              title="一键投屏"
              ><i class="icon-project-btn"></i
            ></span>
            <template v-if="!$g.isDepart">
              <span
                class="pointer recording-btn m-r-sm flex items-center justify-center"
                @click="recordingHandle()"
                v-show="!showStop"
                title="视频录制"
                ><i class="recording-red"></i
              ></span>
              <!-- <span
                class=" recording-btn m-r-sm flex items-center justify-center"
                v-show="showStop"
                ><i class="recording-red recording-animate"></i
              ></span> -->
              <i
                class="pointer icon-a icon-pause m-r-sm"
                v-show="showStop"
                title="停止录制"
                @click="stopRecording()"
              ></i>
            </template>
            <template v-if="$g.isDepart">
              <span
                class="pointer recording-btn m-r-sm flex items-center justify-center"
                @click="recordingDepartHandle()"
                v-show="!showDepartStop"
                title="视频录制"
                ><i class="recording-red"></i
              ></span>
              <i
                class="pointer icon-a icon-pause m-r-sm"
                v-show="showDepartStop"
                title="停止录制"
                @click="stopDepartRecording()"
              ></i>
            </template>
            <a-select
              @change="changeKmHandle"
              v-if="samLonAndLat.length > 1"
              v-model="currentKm"
              size="small"
              style="width: 100px"
              class="m-r-xs"
            >
              <a-select-option
                v-for="item in samLonAndLat"
                :key="item.cameraId"
                :value="item.cameraId"
                >{{ item.khPile }}</a-select-option
              >
            </a-select>
            <span class="text-lightblue pointer" @click.stop="toggleReg"
              >参数调节</span
            >
            <!-- <span class="text-lightblue" @click="downloadVideo()">下载</span> -->
            <!-- <span class="text-lightblue">00:35</span> -->
          </div>
          <div class="flex items-center">
            <template>
              <!-- <a-dropdown>
                <a
                  class="ant-drop-info flex items-center justify-center"
                  @click="(e) => e.preventDefault()"
                >
                  <i class="icon-cameral m-r-xs"></i
                  >{{ selectList[selectIndex] }}
                </a>
                <a-menu slot="overlay" @click="selectHandle">
                  <a-menu-item v-for="(item, index) in selectList" :key="index">
                    <a href="javascript:;"
                      ><i class="icon-cameral m-r-xs"></i>{{ item }}</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown> -->
            </template>
            <span @click="openEditFault" class="cursor" style="color:#ccc" v-if="this.timeData">{{this.foFillName}}：{{this.timeData.time}}恢复</span>
            <i
              class="pointer icon-a icon-fault m-r-sm m-l-sm"
              @click="toggleFaultReported()"
            >异常填报</i>
            <!-- <i
              class="pointer icon-a icon-hd m-r-sm m-l-sm"
              @click="toggleResolution()"
            ></i> -->
            <toggle-resolution
              v-if="definitions.length && resolutionValue !== null"
              class="m-r-sm"
              :resolutionValue="resolutionValue"
              :definitions="definitions"
              @toggleResolutionHandle="toggleResolution"
            ></toggle-resolution>
            <i
              title="切至全屏"
              class="pointer icon-a icon-screen"
              @click="playerFullScreen()"
            ></i>
          </div>
        </div>
      </div>

      <!--  <div
      class="aa absolute"
      style="width:100px;height:100px;background:red;"
    ></div> -->

      <video-name-dialog
        :visible="videoVisible"
        @closeDialog="videoVisible = false"
      ></video-name-dialog>
    </div>
    <div
      class="vd-cloud-control fixed p-sm flex flex-column items-center"
      ref="vdCloud"
      v-if="showCloud"
    >
      <div
        class="vd-c-hd flex items-center full-width"
        @click="showCloud = false"
      >
        <i class="icon-close m-r-xs pointer"></i>云台控制
      </div>
      <div class="vd-direction relative">
        <span
          class="vd-d vd-top absolute pointer"
          @click="controlChange(3)"
        ></span>
        <span
          class="vd-d vd-right absolute pointer"
          @click="controlChange(2)"
        ></span>
        <span
          class="vd-d vd-bottom absolute pointer"
          @click="controlChange(4)"
        ></span>
        <span
          class="vd-d vd-left absolute pointer"
          @click="controlChange(1)"
        ></span>
        <span class="bd-main absolute text-lightblue text">云台</span>
      </div>
      <div class="count">
        <add-minus
          @minus="controlChange(5)"
          @add="controlChange(6)"
          text="变倍"
          class="m-b-xs"
        />
        <add-minus
          @minus="controlChange(7)"
          @add="controlChange(8)"
          text="聚焦"
          class="m-b-xs"
        />
        <add-minus
          @minus="controlChange(9)"
          @add="controlChange(10)"
          text="光圈"
          class="m-b-xs"
        />
      </div>
      <div class="flex m-t-sm items-center step-count">
        <span class="m-l-sm st-wd">步长</span>
        <a-input-number
          :min="1"
          :max="8"
          v-model="stepSize"
          @change="onChange"
          class="flex-one"
        />
      </div>
    </div>
    <!-- 视频调节设置 -->
    <div class="regulation p-sm" ref="regulation" v-show="showReg">
      <!-- <div class="flex flex-end">
        <span
          class="text-lightblue pointer f20 weight bg-white re-close p-w-sm relative"
          @click.stop="showReg = false"
          >×</span
        >
      </div> -->
      <!-- 亮度 -->
      <div class="slider flex items-center justify-center">
        <span class="slider-tit">亮度</span>
        <a-slider v-model="adjustBright" class="flex-60" @change="adjust" />
      </div>
      <!-- 对比度 -->
      <div class="slider flex items-center justify-center">
        <span class="slider-tit">对比度</span>
        <a-slider v-model="adjustContrast" class="flex-60" @change="adjust" />
      </div>
      <!-- 锐度 -->
      <div class="slider flex items-center justify-center">
        <span class="slider-tit">锐度</span>
        <a-slider
          :min="0"
          :max="10"
          class="flex-60"
          v-model="adjustAcutance"
          @change="adjust"
        />
      </div>
    </div>
    <collect-dialog
      :visible="collectVisible"
      @closeCollect="closeCollectHandle"
      @sureCollect="sureCollectHandle"
    />
    <fault-dialog 
      :visible="faultVisible" 
      :cameraId="cameraId"
      :cameraData="cameraData"
      @closeFault="closeFaultHandle"
      @getDetail="getFaultDetails"
      />
    <edit-fault-dialog 
      :visible="editFaultVisible" 
      :cameraId="cameraId"
      @getFaultDetails="getFaultDetails"
      @closeEditFault="closeEditFaultHandle"
      />
    <snap-shot-pic ref="snapShotPicDom" />
    <save-video
      :visible="videoDepartVisible"
      @sure="handleDepartSureVideo"
      @close="handleDepartCancelVideo"
    />
  </div>
</template>

<script>
import VideoNameDialog from './VideoNameDIalog'
import AddMinus from './AddMinus'
import FlvPlayer from '@/components/module/camera/FlvPlayer'
import ThreePlayer from '@/components/module/camera/ThreeVideo'
import CameralDetail from '@/components/module/camera/CameralDetail'
import ToggleResolution from '@/components/module/camera/ToggleResolution'
import CollectDialog from './CollectDialog'
import FaultDialog from '@/components/fault-dialog'
import EditFaultDialog from '@/components/edit-fault-dialog'
import SnapShotPic from '@/components/snapShot/SnapShotPic'
import SaveVideo from '@/components/videos/SaveVideo'
import { mapActions, mapState, mapMutations } from 'vuex'
import moment from 'moment';

import {
  setSessionStorage,
  getSessionStorage
} from '@/tools'
import { saveAs } from '@/tools'
import { CODE_OK } from '@/request/config_code'

let tempCameraInfo = null

export default {
  name: 'VideoDialog',

  props: {
    visible: {
      type: Boolean
    },
    cameraId: {
      type: String,
      default: ''
    },
    cameraNum: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      selectList: ['K120+200', 'K220+100', 'K120+50'],
      selectIndex: 0,
      videoVisible: false,
      showCloud: false,
      showCloudWidth: false,
      currentCamera: {},
      eventCameraNum: null,
      isThreePlayer: 0,
      getUrlLoading: false,
      resolutionValue: '', // 分辨率选项值（初始为空，默认不传or传空）
      definitions: [], // 清晰度选项
      currentCameraNameStr: '',
      isAliPlayer: false,
      stepSize: 3,
      showDetail: false,
      cameraInfo: {},
      mediaStream: '',
      recorder: null,
      chunk: null,
      showStop: false, //省平台
      collectVisible: false,
      faultVisible: false, // 故障上报弹窗显隐
      editFaultVisible:false, //编辑故障上报
      adjustBright: 80, // 视频亮度调节
      adjustContrast: 90, //视频对比度调节
      adjustAcutance: 0, //视频锐调节
      showReg: false,
      showDepartStop: false, //部平台是否设置stop
      videoDepartVisible: false, //部平台保存录像
      videoData: {}, //保存视频数据
      currentVideoId: '',
      samLonAndLat: [],
      currentKm: '',
      foFillName:'', // 故障名称
      currentActiveIndex: 0,
      noToggleResolution: false,
      timeData: {
        time:''
      },
      cameraData:{}
    }
  },

  components: {
    VideoNameDialog,
    AddMinus,
    FlvPlayer,
    ThreePlayer,
    CameralDetail,
    ToggleResolution,
    CollectDialog,
    FaultDialog,
    EditFaultDialog,
    SnapShotPic,
    SaveVideo
  },

  computed: {
    ...mapState({
      dragMove: (state) => state.home.dragMove,
      sessionCurrentCamera: (state) =>
        state.collect.sessionCurrentCamera,
      projectionVideoList: (state) =>
        state.home.projectionVideoList,
      videoSize: (state) => state.home.videoSize,
      currentVideoIndex: (state) =>
        state.home.currentVideoIndex,
      splitScreenInfo: (state) =>
        state.home.splitScreenInfo,
      screenActiveIndex: (state) =>
        state.home.screenActiveIndex,
      currentScreen: (state) => state.home.currentScreen
    }),
    usePlayerType() {
      // return 'ali';
      return this.$root.playerType
    },
    isDisplayCloud() {
      return this.cameraInfo.cameraType == '监控型球机'
    },
    isPlatformDisplay() {
      return this.$g.isDepart
        ? this.isDisplayCloud &&
            this.hasPermission(1092101211)
        : this.hasPermission(1092101211)
    }
    /* vLeft() {
      return this.$refs.vdInner && this.$refs.vdInner.offsetLeft;
    }, */
  },

  watch: {
    /**
     * 监视视频播放窗口状态变化
     */
    visible(nv) {
      this.timeData = null
      if (nv) {
        //记录播放历史
        this.setCameraHistory()
        // this.getFaultDetails()
        // this.resolutionValue = 0
        // this.currentActiveIndex = this.screenActiveIndex;
        this.currentActiveIndex = this.screenActiveIndex[
          this.currentScreen
        ]

        // 获取分辨率选项
        this.getDefinitions()
      } else {
        //关闭情况下情况地图marker点
        this.$root.$emit('removeEventMarker')

        // 初始化分辨率选项和默认值
        this.resolutionValue = ''
        this.defaultDefinition = null
        this.definitions = []
      }
    },

    cameraId(nV) {
      if (nV) {
        // 初始化分辨率选项和默认值
        this.resolutionValue = ''
        this.defaultDefinition = null
        this.definitions = []
      }
    },

    cameraNum(nV) {
      if (nV) {
        // 获取分辨率选项
        this.getDefinitions()
      }
    },

    resolutionValue(nV, oV) {
      oV > 0 && this.stopStreamMulHandle(~oV)
    },

    dragMove: {
      handler(nValue) {
        this.setPosition(nValue)
      },
      deep: true
    },

    sessionCurrentCamera: {
      handler(nValue, oValue) {
        if (nValue.hasFavorited === oValue.hasFavorited) {
          return
        }
        this.currentCamera = nValue
      },
      deep: true
    }

    /*  vLeft(nv) {

      if (nv) {
        this.setPosition();
      }
    }, */
  },

  methods: {
    moment,
    ...mapActions([
      'cameraYtControlAction',
      'getCameraInfoByList',
      'addCollectItem',
      'deleteCollectItem',
      'deleteFolderCollectItem',
      'getCollectList',
      'getCameraHistory',
      'getHomeDefaultCamera'
    ]),
    ...mapMutations([
      'setSessionCurrentCamera',
      'setProjectionVideoList',
      'setNeedStopCamera'
    ]),
    // 获取故障详情
    getFaultDetails() {
      let params = {
        cameraId: this.cameraId,
      }
      console.log('params', params)
      this.$api.home
        .faultDetails(params)
        .then((res) => {
          if (res.code === 200) {
            // this.timeData = this.moment(res.data?.restoreTime).format('YYYY-MM-DD')
            this.timeData = res.data
            this.cameraData = res.data
            this.timeData.time = res.data?.restoreTime.slice(0,10)
            let index = this.timeData.faultType.lastIndexOf('/')
            this.foFillName = this.timeData.faultType.substring(index+1,this.timeData.faultType.length);
            // alert(this.timeData)
            console.log('111',res.data)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    
    // 打开编辑故障弹窗
    openEditFault(){
      this.editFaultVisible = true
    },

    //关闭编辑故障弹窗
    closeEditFaultHandle() {
      this.editFaultVisible = false
    },
    //控制千米桩
    changeKmHandle(e) {
      let cameraInfo = this.samLonAndLat.find(
        (item) => e === item.cameraId
      )
      this.getVideoUrlToPlay(cameraInfo)
    },
    //获取相同经纬度
    getSameLonAndLat(cameraId) {
      let data = {
        params: {},
        cameraId
      }
      this.$api.home.getSameLonAndLat(data).then((res) => {
        if (res.code === 200) {
          this.samLonAndLat = res.data
        }
      })
    },
    //调节明暗度
    adjust() {
      this.$refs.flvPlay.adjust(
        this.adjustBright,
        this.adjustContrast,
        this.adjustAcutance
      )
    },
    //设置高低清
    setResolution(value) {
      this.resolutionValue = value
    },
    setNoToggleResolution(value) {
      this.noToggleResolution = value === 1 ? true : false
    },
    //获取高低清
    getResolution() {
      return this.resolutionValue
    },
    //记录摄像机历史
    setCameraHistory() {
      this.$api.aiCenterY
        .setCameraHistory(this.cameraId)
        .then((res) => {
          if (res.code === 200) {
            this.getCameraHistory()
          }
        })
    },
    setPosition(p) {
      /*  const left = this.$refs.vdInner.offsetLeft;
      const top = this.$refs.vdInner.offsetTop;
      const wd = this.$refs.vdInner.clientWidth;
*/
      if (this.$refs.vdCloud) {
        this.$refs.vdCloud.style.left = p.x1 + 'px'
        this.$refs.vdCloud.style.top = p.y1 + 'px'
        this.$refs.vdCloud.style.height = p.h1 + 'px'
        // this.$refs.wrap.style.width = '50%';
      }
      if (this.$refs.regulation) {
        this.$refs.regulation.style.left = p.x2 + 'px'
        this.$refs.regulation.style.top = p.y2 + 'px'
        this.$refs.regulation.style.width = p.h2 + 'px'
      }
    },
    addCollectItemHandle() {
      /* if (this.currentCamera.hasFavorited) {
        this.$message.info('已经收藏过了，不好再操作！');
        return;
      } */
      /*  if (this.$g.isDepart) {
        // this.collectVisible = true;
        this.addFolderCollect();
      } else {
        this.addSingleCollect();
      } fixsxy*/
      this.addFolderCollect()
    },
    //添加收藏夹
    addFolderCollect() {
      /*let sessStor = getSessionStorage('currentCamera');
      if (
        (sessStor &&
          sessStor.cameraId === this.currentCamera.cameraId &&
          sessStor.hasFavorited) ||
        this.currentCamera.hasFavorited
      ) {
        if (!this.hasPermission(109210101014)) {
          this.permissionWarning();
          return;
        }

        let params = {
          cameraId: this.currentCamera.cameraId,
          folderId: sessStor.folderId,
        };
        let obj = {
          params,
          instructionsDel: {
            module: '重点关注',
            page: '收藏列表',
            feature: '取消收藏',
            description: `取消收藏: ${this.currentCamera.cameraName}`,
          },
        };
        this.$confirm({
          title: '提示',
          content: '确认取消收藏该路摄像机?',
          onOk: () => {
            this.deleteFolderCollectItem(obj).then((data) => {
              if (data.code === 200) {
                this.$message.success('取消收藏成功！');
                this.$set(this.currentCamera, 'hasFavorited', false);
                setSessionStorage('currentCamera', this.currentCamera);

                //刷新收藏列表
                this.getCollectList();
                // this.getHomeDefaultCamera();
              }
            });

          },
          onCancel: () => {},
        });
      } else {*/
      if (!this.hasPermission(109210101013)) {
        this.permissionWarning()
        return
      }

      this.collectVisible = true

      /*}
      this.setSessionCurrentCamera(this.currentCamera);*/
    },
    //单击收藏
    addSingleCollect() {
      let sessStor = getSessionStorage('currentCamera')
      if (
        (sessStor &&
          sessStor.cameraId ===
            this.currentCamera.cameraId &&
          sessStor.hasFavorited) ||
        this.currentCamera.hasFavorited
      ) {
        let params = {
          cameraId: this.currentCamera.cameraId,
          folderId: sessStor.folderId
        }
        let obj = {
          params,
          instructionsDel: {
            module: '重点关注',
            page: '收藏列表',
            feature: '取消收藏',
            description: `取消收藏: ${this.currentCamera.cameraName}`
          }
        }
        this.deleteFolderCollectItem(obj).then((data) => {
          if (data.code === 200) {
            this.$message.success('取消收藏成功！')
            this.$set(
              this.currentCamera,
              'hasFavorited',
              false
            )
            setSessionStorage(
              'currentCamera',
              this.currentCamera
            )

            //刷新收藏列表
            this.getCollectList()
            // this.getHomeDefaultCamera();
          }
        })
      } else {
        this.addCollectItem(
          this.currentCamera.cameraId
        ).then((data) => {
          if (data.code === 200) {
            this.$message.success('收藏视频成功！')
            this.$set(
              this.currentCamera,
              'hasFavorited',
              true
            )
            setSessionStorage(
              'currentCamera',
              this.currentCamera
            )
            //刷新收藏列表
            this.getCollectList()
            // this.getHomeDefaultCamera();
          }
        })
      }
      this.setSessionCurrentCamera(this.currentCamera)
    },
    //选择控制
    selectHandle({ key }) {
      this.selectIndex = key
    },
    //关闭控制
    closeHandle(e) {
      this.timeData = null
      //关闭按钮如果在录制则提示停止录制
      if (this.showDepartStop) {
        this.$confirm({
          title: '提示',
          content: '你确定停止录制吗?',
          onOk: () => {
            this.stopRecordingBeyondTime().then((res) => {
              if (res) {
                this.closeDialogInfo()
              }
            })
          },
          onCancel: () => {}
        })
      } else {
        this.closeDialogInfo()
      }

      e.stopPropagation()
      this.$emit('close', this.$el)
    },
    closeDialogInfo() {
      // this.$root.$emit('mapPan', '');
      this.showDetail = false
      this.stepSize = 1
      this.showCloud = false
      // this.resolutionValue = 0
      this.showStop = false
      //关闭停止录制
      this.recorder &&
        this.recorder.state == 'recording' &&
        this.stopRecording()
      this.currentCameraNameStr = '' //清除当前cameraName
      this.adjustBright = 80 // 视频亮度调节
      this.adjustContrast = 90 //视频对比度调节
      this.adjustAcutance = 0 //视频锐调节
      this.showReg = false
      //部平台录像按钮重置。
      /**重置录制信息 */
      this.videoData = {} //保存视频数据

      this.currentVideoId = ''

      //清除桩号
      this.currentKm = ''

      this.reconnectTimer &&
        clearTimeout(this.reconnectTimer)
      this.$emit('update:visible', false)

      this.$nextTick(() => {
        //清掉camera
        this.currentCamera = {}
        this.setNeedStopCamera(this.currentCamera)
      })
      if (
        this.usePlayerType === 'flv' &&
        !this.isAliPlayer
      ) {
        if (this.usePlayerType === 'flv') {
          if (this.isThreePlayer) {
            this.$refs['threePlay'].flv_destroy()
          } else {
            this.$refs['flvPlay'].flv_destroy()
          }
        }
      }
      if (
        this.isAliPlayer ||
        this.usePlayerType === 'ali'
      ) {
        this.$refs['aliplayer'].disposePlayer()
      }
    },
    /**
     * 给窗口定位
     */
    /**
     * 给窗口定位
     */
    // setWindowSite(mouseX, mouseY) {
    //   let that = this;
    //   this.$nextTick(() => {
    //     that.videoDialogElement = document.querySelector('.video-dialog-group');

    //     //优先显示到顶部，底部不够显示完整视频，再放到底部
    //     let cameraHeight = that.videoDialogElement.offsetHeight,
    //         // cmaeraWidth = that.videoDialogElement.offsetWidth,
    //         top = mouseY - cameraHeight/2,
    //         left = mouseX + 100;
    //     if (top < 20) {
    //       top = mouseY + 20;
    //     }
    //     that.videoDialogElement.style.left = left + 'px';
    //     that.videoDialogElement.style.top = top + 'px';
    //     that.videoDialogElement.style.transform = 'none';
    //   });
    // },
    setWindowSite2(mouseX, mouseY) {
      let that = this

      this.$nextTick(() => {
        that.videoDialogElement = this.$refs.wrap

        if (this.videoDialogElement) {
          let cameraHeight =
              that.videoDialogElement.offsetHeight,
            top = mouseY - cameraHeight - 20,
            left = mouseX - this.baseWindowWidth / 2

          if (top < 20) {
            top = mouseY + 20
          }

          that.videoDialogElement.style.left = left + 'px'
          that.videoDialogElement.style.top = top + 'px'
          that.videoDialogElement.style.transform = 'none'
        }
      })
    },
    //高清停流
    stopMapStream(currentCamera) {
      let cameraNums = [currentCamera.cameraNum]
      let data = {
        cameraNums,
        streamType: -2
      }
      this.$api.home.stopStream(data).then(() => {
        /*  if (res.code) {
          console.log('停止高清推流');
        } */
      })
    },
    /**
     * 获取播放地址
     * @param cameraNum
     */
    getVideoUrlToPlay(cameraInfo, change, callback) {
      // debugger;
      if (!cameraInfo) return false

      if (change === 'map') {
        if (
          tempCameraInfo &&
          tempCameraInfo.resolutionValue === 1
        ) {
          this.stopMapStream(tempCameraInfo)
        }
        tempCameraInfo = cameraInfo
      } else if (!change) {
        tempCameraInfo = cameraInfo
      }
      let that = this,
        currentCameraSession = getSessionStorage(
          'currentCamera'
        ),
        cameraNum = cameraInfo.cameraNum
      that.currentCamera = cameraInfo
      //设置当前摄像机信息，作为与高清投屏对比，判断是否下发停流
      this.setNeedStopCamera(this.currentCamera)
      that.eventCameraNum = cameraNum
      //设置当前桩号
      this.currentKm = cameraInfo.cameraId
      // console.error('11111111111111111111', cameraInfo, currentCameraSession);
      //这里对session进行判断，为了防止收藏后，再次点击不能显示收藏状态，前端进行控制
      if (
        currentCameraSession &&
        currentCameraSession.cameraId ===
          cameraInfo.cameraId
      ) {
        this.$set(
          that.currentCamera,
          'hasFavorited',
          currentCameraSession.hasFavorited
        )
      } else if (
        cameraInfo.collectFlag == 1 ||
        cameraInfo.id
      ) {
        that.$set(that.currentCamera, 'hasFavorited', true)
      }
      /* if (
        [
          'zsy_qjsxj_00001',
          'zsy_qjsxj_00002',
          'zsy_qjsxj_00003',
          'zsy_qjsxj_hall_01',
        ].indexOf(cameraNum) != -1
      ) {
        that.isThreePlayer = 1;
      } else if (['zsy_qjsxj_hall_02'].indexOf(cameraNum) != -1) {
        that.isThreePlayer = 2;
      } else {
        that.isThreePlayer = 0;
      } */
      // that.isThreePlayer = cameraInfo.cameraType == 3; //sxy判断全景,云南没有全景
      that.getUrlLoading = true
      //m3u8Url 播放器暂时有问题
      let mediatype = this.$root.mediatype // 'flvUrl';//rtmpUr flvUrl m3u8Url

      let obj = {
        cameraNum: cameraNum, //'e5228b11-f64a-4669-93e2-8dc4b5d09a32', //record.data.cameraName,
        // videoType: that.resolutionValue || 0,fixsxy
        videoType: this.resolutionValue,
        mediatype: mediatype,
        source: cameraInfo.source
      }
      this.videoData = {
        cameraId: cameraInfo.cameraId,
        videoType: obj.videoType,
        cameraNum: obj.cameraNum
      }
      this.$api.home
        .getCameraDetail(this.currentCamera.cameraNum)
        .then((res) => {
          if (res.code == 200) {
            this.cameraInfo = res.data
            this.currentCameraNameStr =
              res.data.cameraName || '摄像机名称'
          }
        })
      //获取相同的千米桩
      this.getSameLonAndLat(this.currentCamera.cameraId)
      let objParams = {
        data: obj,
        instructionsDel: {
          module: '地图调阅',
          page: '视频调阅',
          feature: `播放${{
            0: '标清',
            1: '高清',
            2: '原画'
          }[obj.videoType] || ''}视频`,
          description: `播放${{
            0: '标清',
            1: '高清',
            2: '原画'
          }[obj.videoType] || ''}视频: ${
            this.currentCamera.cameraName
          }`
        }
      }
      this.$api.home
        .getCameraPlayUrl(objParams)
        .then((res) => {
          if (res.code === 200) {
            // console.error('haha----', res);
            if (!res.data || !res.data.flv) {
              this.$message.error('视频地址请求失败')
              this.$emit('update:visible', false)
              return false
            }

            // 首次默认清晰度赋值
            if (this.defaultDefinition === null) {
              this.defaultDefinition = res.data.videoType
              this.resolutionValue = this.defaultDefinition
            }

            that.videoUrl = res.data.flv //res.data;
            // this.videoUrl =
            //   'https://cqplay.gandongyun.com/live/794e46d0-09b3-44fa-8e7b-c6838487a344.flv?txSecret=a65cf89fdc9f20f9a5c092e185198119&txTime=60DB3929';
            // // that.currentCameraNameStr = res.data.cameraName || '摄像机名称';
            that.$forceUpdate()

            if (that.isThreePlayer) {
              that.$refs['threePlay'].flv_Play(
                that.videoUrl
              )
            } /* else if (that.isThreePlayer == 2) {
            that.$refs.hlsPlay.hls_play(that.videoUrl);
          }  */ else {
              obj.levelCount = 0 //限定3次重连
              obj.reconnectFlag = true
              that.$refs.flvPlay.flv_destroy() //消除实例
              that.$refs.flvPlay.flv_Play(
                that.videoUrl,
                obj
              )
            }

            that.getUrlLoading = false

            //调用详情接口
            /* this.getCameraInfoByList({
            currPage: 1,
            pageSize: 1,
            cameraName: this.currentCamera.cameraName,
          }).then((res) => {
            // console.error('haha1', res);
            if (res.code === 200) {
              this.cameraInfo = res.data[0];
              this.currentCameraNameStr =
                res.data[0].cameraNames || '摄像机名称';
            }
          }); */

            callback && callback.apply(this, arguments)
          } else {
            // this.reconnect();
            this.$message.error('请求失败！')
          }
        })
      /* .catch(() => {
          this.reconnect();
        }) */
    },

    // 获取视频清晰度选项
    getDefinitions() {
      this.$api.home
        .getStreamMediaByCameraNum({
          cameraNum: this.cameraNum
        })
        .then((res) => {
          this.definitions = res.resolutions

          /* 分辨率下拉框显示细节优化 */
          // 若 仅一个选项
          if (this.definitions.length === 1) {
            // 则为其
            this.resolutionValue = this.definitions[0].templateId
          }
          // 若 不止一个选项
          else if (this.definitions.length > 1) {
            // 未获取到默认分辨率则取第一个
            this.resolutionValue = this.defaultDefinition
          }
        })
    },

    reconnect() {
      this.reconnectTimer &&
        clearTimeout(this.reconnectTimer)
      this.reconnectTimer = setTimeout(() => {
        console.log('info', '3s后断线重连')
        this.getVideoUrlToPlay(this.currentCamera)
      }, 3000)
    },
    playerFullScreen() {
      /*if(that.usePlayerType === 'ali') {
                    that.$refs['ckVideo'].ck_play(res.data);
                }*/
      if (this.usePlayerType === 'flv') {
        if (this.isThreePlayer) {
          this.$refs['threePlay'].flv_fullScreen()
        } else {
          this.$refs['flvPlay'].flv_fullScreen()
        }
      }
    },
    minusHandle() {},
    addHandle() {},
    onChange(value) {
      // console.log('changed', value);
      this.stepSize = value
    },
    toggleReg() {
      if (!this.hasPermission(1092101211)) {
        this.permissionWarning()
        return
      }

      this.showReg = !this.showReg
      this.$nextTick(() => {
        const left = this.$refs.wrap.offsetLeft
        const top = this.$refs.wrap.offsetTop
        const wd = this.$refs.wrap.clientWidth
        const h = this.$refs.wrap.offsetHeight
        this.setPosition({ x2: left, y2: top + h, h2: wd })
      })
    },
    toggleCloud() {
      if (!this.hasPermission(1092101211)) {
        this.permissionWarning()
        return
      }
      // debugger;
      /*  clearTimeout(this.timer);
      if (!this.showCloud) {
        this.showCloud = true;
        this.timer = setTimeout(() => {
          this.showCloudWidth = this.showCloud;
        }, 10);
      } else {
        this.showCloudWidth = false;
        this.timer = setTimeout(() => {
          this.showCloud = false;
        }, 10);
      } */
      this.showCloud = !this.showCloud
      this.$nextTick(() => {
        const left = this.$refs.wrap.offsetLeft
        const top = this.$refs.wrap.offsetTop
        const wd = this.$refs.wrap.clientWidth
        const h = this.$refs.wrap.offsetHeight
        this.setPosition({ x1: left + wd, y1: top, h1: h })
      })
    },
    toggleFaultReported(){
      this.faultVisible = true
    },
    toggleResolution(resolutionValue) {
      // debugger;
      this.resolutionValue = resolutionValue
      this.currentCamera.resolutionValue = this.resolutionValue

      this.$message.warning('切换中....')
      this.getVideoUrlToPlay(
        this.currentCamera,
        false,
        () => {
          this.$message.success(
            `切换${{ 0: '标清', 1: '高清', 2: '原画' }[
              resolutionValue
            ] || ''}成功`
          )
        }
      )
    },
    controlChange(action) {
      // if (!this.cameraInfo) return false;
      // console.log(this.currentCamera);
      let params = {
        cameraNum: this.currentCamera.cameraNum,
        cameraName: this.currentCamera.cameraName,
        // videoType: 10,
        action: action,
        step: this.stepSize
      }
      this.cameraYtControlAction(params)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('云台调整下发成功')
          }
        })
        .catch(() => {})
    },
    imgCut() {
      if (!this.hasPermission(1092101212)) {
        this.permissionWarning()
        return
      }

      if (this.usePlayerType === 'flv') {
        if (this.isThreePlayer) {
          this.$refs['threePlay'].cut()
        } else {
          this.$refs['flvPlay'].cut()
        }
      }
    },
    recordingHandle() {
      if (!this.hasPermission(1092101214)) {
        this.permissionWarning()
        return
      }

      if (this.isThreePlayer) {
        this.videoElement = this.$refs[
          'threePlay'
        ].getVideoElement()
      } else {
        this.videoElement = this.$refs[
          'flvPlay'
        ].getVideoElement()
      }

      let canvasElement = document.querySelector('#canvas')
      this.videoWidth = 640
      this.videoHeight = 360
      const frameRate = 60
      const encodeType = 'video/webm;codecs=vp8'

      this.chunks = []

      this.frameId = null
      //设置画布背景
      this.canvasContext = canvasElement.getContext('2d')
      this.canvasContext.fillStyle = 'deepskyblue'
      this.canvasContext.fillRect(
        0,
        0,
        canvasElement.width,
        canvasElement.height
      )

      //清除定时器,1h后停止录制
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.stopRecording()
      }, 60 * 60 * 1000)
      //创建MediaRecorder，设置媒体参数
      const stream = canvasElement.captureStream(frameRate)
      this.recorder = new MediaRecorder(stream, {
        mimeType: encodeType
      })

      this.recorder.onstop = () => {
        let duration = Date.now() - this.startTime
        let buggyBlob = new Blob(this.chunks, {
          type: 'video/webm'
        })

        window.ysFixWebmDuration(
          buggyBlob,
          duration,
          (fixedBlob) => {
            this.downloadVideo(fixedBlob)
          }
        )
      }
      //收集录制数据
      this.recorder.ondataavailable = (e) => {
        this.chunks.push(e.data)
      }
      this.recorder.start(10)
      this.startTime = Date.now()
      this.showStop = true
      this.drawFrame()
    },
    drawFrame() {
      this.canvasContext.drawImage(
        this.videoElement,
        0,
        0,
        this.videoWidth,
        this.videoHeight
      )
      this.frameId = requestAnimationFrame(this.drawFrame)
    },
    stopRecording() {
      if (!this.recorder) {
        return
      }
      //清除定时器
      clearTimeout(this.timer)
      this.recorder.stop()
      cancelAnimationFrame(this.frameId)
      this.showStop = false
      this.recorder = null //销毁录制对象

      // this.downloadVideo();
    },
    recordingHandle1() {
      let constraints = {
        video: {
          cursor: 'never'
        }, // 视频信息的设置
        audio: false, // 是否包含音频信息
        logicalSurface: false // 设置是否包含所选屏幕外区域的一些信息
      }
      // navigator.mediaDevices.getDisplayMedia(constraints) // 录制屏幕共享

      // 录制摄像头
      navigator.mediaDevices
        .getDisplayMedia(constraints)
        .then((stream) => {
          var options = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 2500000,
            // mimeType: 'video/mp4;codecs=vp8,opus',
            mimeType: 'video/webm;codecs=vp8,opus'
          }
          /*  var options = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 2500000,
            mimeType: 'video/mp4',
          }; */
          this.mediaStream = stream
          this.recorder = new MediaRecorder(stream, options)

          this.recorder.ondataavailable = (e) => {
            this.chunk = e.data
          }

          this.recorder.start()
        })
    },
    /* recoder(stream) {
      var options = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
        mimeType: 'video/mp4',
      };
      var mediaRecorder = new MediaRecorder(stream, options);
      m = mediaRecorder;
    }, */
    stopRecording1() {
      if (this.recorder.state != 'recording') {
        this.$message.warn('你尚未录制，不好停止录制')
        return
      }
      this.recorder.stop()
      this.mediaStream.getTracks().forEach((track) => {
        track.stop()
      })
    },
    downloadVideo(chunk) {
      let file = new File([chunk], '视频.mp4', {
        type: 'video/mp4'
      })
      saveAs(file)
    },
    downloadVideo2() {
      /* if (!this.chunk) {
        this.$message.warn('你尚未录制视频');
        return;
      }
       let url = URL.createObjectURL(this.chunks); webRTC
      */
      let blob = new Blob(this.chunks)
      let url = URL.createObjectURL(blob)
      // 创建隐藏的可下载链接
      var eleLink = document.createElement('a')
      eleLink.download = '录制视频.webm'
      // eleLink.style.display = 'none';
      // 字符内容转变成blob地址
      eleLink.href = url
      // 触发点击
      console.log('url', url)
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },

    dragHandle(obj) {
      console.error('sxy-----------', obj)
    },
    projectionHandle() {
      if (!this.hasPermission(1092101213)) {
        this.permissionWarning()
        return
      }
      //为了打开了播放器，再此切换分屏序号
      this.currentActiveIndex = this.screenActiveIndex[
        this.currentScreen
      ]
      // let newProVideoList = [];fixsxy以前
      let proVideoList = this.projectionVideoList //投屏视频
      let videoLength = this.videoSize[this.currentScreen]
      let isRepeat = false
      proVideoList.forEach((item) => {
        if (
          item.cameraNum === this.currentCamera['cameraNum']
        ) {
          isRepeat = true
        }
      })
      if (isRepeat) {
        this.$message.error(
          '该视频已经在投屏区，不能重复投屏'
        )
        return
      }
      /*  if (proVideoList.length < videoLength) {
        newProVideoList = [...proVideoList, this.currentCamera];
      } else if (proVideoList.length === videoLength) {
        proVideoList[videoLength - 1] = this.currentCamera;
        newProVideoList = [...proVideoList];
      } fixsxy以前*/
      // debugger
      console.log(
        '看一下',
        this.projectionVideoList,
        this.currentVideoIndex,
        this.currentActiveIndex
      )
      this.projectionVideoList[
        this.currentActiveIndex
      ] = this.currentCamera
      this.currentActiveIndex =
        this.currentActiveIndex >= videoLength - 1
          ? 0
          : ++this.currentActiveIndex
      console.log(
        'projectionVideoList',
        this.currentActiveIndex
      )
      this.setProjectionVideoList(this.projectionVideoList)
      // console.log('haha', proVideoList, videoLength);
      // debugger;
      this.splitScreenInfo.setCameraList(
        this.projectionVideoList,
        false
      )
      this.splitScreenInfo.setActiveBorder(
        this.currentActiveIndex
      )
    },
    //关闭故障上报
    closeFaultHandle() {
      this.faultVisible = false
    },
    //关闭收藏
    closeCollectHandle() {
      this.collectVisible = false
    },
    sureCollectHandle(selectText) {
      let data = {
        cameraId: this.currentCamera.cameraId,
        folderId: selectText
      }
      let objParams = {
        data,
        instructionsDel: {
          module: '地图调阅',
          page: '视频调阅',
          feature: '收藏视频',
          description: `收藏视频: ${this.currentCamera.cameraName}`
        }
      }
      this.$api.home
        .createFavorite(objParams)
        .then((data) => {
          if (data.code === 200) {
            this.$message.success('收藏视频成功！')
            this.$set(
              this.currentCamera,
              'hasFavorited',
              true
            )
            setSessionStorage(
              'currentCamera',
              this.currentCamera
            )
            //刷新收藏列表
            this.getHomeDefaultCamera()
            this.collectVisible = false
          } else {
            this.$message.error(data.message)
            this.collectVisible = false
          }
        })
    },
    getBase64Handle(base64) {
      let params = {
        cameraId: this.currentCamera.cameraId,
        cameraNum: this.currentCamera.cameraNum,
        cameraName: this.currentCamera.cameraName,
        cutUrl: base64
      }
      this.$refs.snapShotPicDom.show(params)
    },
    //部平台开始录制
    recordingDepartHandle() {
      if (!this.hasPermission(1092101214)) {
        this.permissionWarning()
        return
      }

      this.videoDepartVisible = true
    },
    //部平台停止录制
    stopDepartRecording() {
      /*  this.videoDepartVisible = true; */
      this.$confirm({
        title: '提示',
        content: '你确定停止录制吗?',
        onOk: () => {
          /*  this.endDepartTime = +new Date();
          if (this.endDepartTime - this.startDepartTime <= 2 * 60 * 1000) {
            this.$message.error('录制时间不能低于2分钟');
            return;
          }

          this.showDepartStop = false; */
          this.stopRecordingBeyondTime()
        },
        onCancel: () => {}
      })
    },
    stopRecordingBeyondTime() {
      this.endDepartTime = +new Date()
      if (
        this.endDepartTime - this.startDepartTime <=
        2 * 60 * 1000
      ) {
        this.$message.error(
          '录制时间不能低于2分钟'
        ) /**2 * 60 * 1000 */
        return
      }
      return this.stopManuaRecordDepart()
    },
    //录像保存确定(开始录制)
    handleDepartSureVideo(data) {
      this.videoDepartVisible = false
      this.videoData = { ...this.videoData, ...data }
      // console.log('haha', this.videoData);
      this.recordDepart() //fixsxy
      this.startDepartTime = +new Date()
    },
    //录制请求
    recordDepart() {
      let objParams = {
        data: this.videoData,
        instructionsDel: {
          module: '地图调阅',
          page: '视频调阅',
          feature: '本地录像',
          description: `本地录像: ${this.currentCamera.cameraName}`
        }
      }
      this.$api.video
        .manualBeginRecord(objParams)
        .then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('开始录制成功！')
            this.currentVideoId = res.data
            this.showDepartStop = true
            this.videoTimer = setTimeout(() => {
              this.stopManuaRecordDepart(1)
            }, 2 * 60 * 60 * 1000)
          } else {
            this.$message.error('开始录制出错！')
            this.showDepartStop = false
          }
        })
    },
    //停止录制
    stopManuaRecordDepart(flag) {
      clearTimeout(this.videoTimer)
      return this.$api.video
        .manualEndRecord(this.currentVideoId)
        .then((res) => {
          if (res.code === CODE_OK) {
            if (flag === 1) {
              this.$warning({
                title: '视频录制已终止',
                centered: true,
                closable: true,
                class: 'warning-modal',
                // JSX support
                content: (
                  <div>
                    视频录制时长超过120分钟，已自动停止录制，可在
                    资源中心>截图与录像管理中查看录制完成的视频。
                  </div>
                ),
                onCancel() {}
              })
            } else {
              this.$message.success('停止录制成功！')
            }

            this.showDepartStop = false
            return true
          } else {
            this.$$message.error('停止录制出错！')
            this.showDepartStop = false
          }
        })
    },
    //录像保存取消
    handleDepartCancelVideo() {
      this.videoDepartVisible = false
      this.showDepartStop = false
    },

    //高清停流
    stopStreamMulHandle(streamType) {
      console.log('[this.currentCamera?.cameraNum]', [
        this.currentCamera?.cameraNum
      ])
      //判断是否停流
      // debugger;
      let flag = true
      for (
        let i = 0;
        i < this.projectionVideoList.length;
        i++
      ) {
        if (
          this.currentCamera?.cameraNum ===
          this.projectionVideoList[i].cameraNum
        ) {
          flag = false
          break
        }
      }
      if (!flag) {
        return
      }

      let cameraNums = [this.currentCamera?.cameraNum]
      let data = {
        cameraNums,
        streamType
      }
      this.$api.home.stopStream(data).then(() => {
        /*  if (res.code) {
          console.log('停止高清推流');
        } */
      })
    }
  },

  created() {},

  destroyed() {
    console.log('销毁了')
    this.videoTimer && clearTimeout(this.videoTimer)
    this.reconnectTimer && clearTimeout(this.reconnectTimer)
  }
}
</script>

<style scoped>
.video-dialog-group {
  position: fixed;
  z-index: 1000;
  /* background: rgba(0, 0, 0, 0.4); */
  /*  left: 0;
  top: 0;
  right: 0;
  bottom: 0; */
  /*  width: 36%;
  height: 60%;fixsxy */
  width: 34%;
  height: 48%;
  right: 14%;
  top: 20%;
  box-shadow: 0 0 10px 2px #4f97f8;
  /* transform: translate(-50%, -50%); */
}
.video-dialog-group .vd-inner {
  /* width: 36%;
  height: 60%; */
  width: 100%;
  height: 100%;
  /* background: url('../assets/dialog-bg.png') no-repeat; */
  background-size: 100% 100%;
  padding: 0 10px;
  background: #101d35;
}
.vd-inner .vd-i-hd {
  line-height: 35px;
}
.dot {
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  transform-origin: center center;
  display: inline-block;
}
.online-dot {
  background: red;
}
.icon-c {
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.icon-collect {
  background-image: url("../assets/home/group-collect.png");
}
.icon-collect.current {
  background-image: url("../assets/home/group-collect-on.png");
}
.wd40 {
  width: 200px;
}
.vd-i-content {
  height: calc(100% - 70px);
  padding-bottom: 5px;
}
.icon-a {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.icon-cut {
  background-image: url("../assets/dialog/icon-cut.png");
}
.icon-stop {
  background-image: url("../assets/dialog/icon-stop.png");
}
.icon-pause {
  background-image: url("../assets/dialog/icon-pause.png");
}
.icon-screen {
  background-image: url("../assets/dialog/icon-screen.png");
}
.icon-hd {
  background-image: url("../assets/dialog/icon-hd.png");
}
.icon-fault {
  /* background-image: url("../assets/dialog/icon-hd.png"); */
  display: inline-block;
  font-style: unset;
  width: 70px;
  font-size: 14px;
  color: #86b3e9;
  padding: 0 5px;
  border: 1px solid #86b3e9;
  border-radius: 2px;

}
.icon-cameral {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("../assets/dialog/icon-cameral.png") no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
}
.ant-drop-info {
  width: 96px;
  border: 1px solid #86b3e9;
  border-radius: 2px;
  font-size: 12px;
  height: 24px;
  color: #86b3e9;
}
.regulation {
  position: fixed;
  z-index: 99999;
  background: #101d35;
  left: 36%;
  top: 68%;
  width: 34%;
  margin-top: 4px;
  box-shadow: 0 0 10px 2px #4f97f8;
}
.regulation .slider-tit {
  flex: 0 0 50px;
  margin-right: 10px;
  text-align: right;
}
.regulation .flex-60 {
  flex: 0 0 70%;
}
.vd-cloud-control {
  /* width: 20%; */
  width: 220px;
  /* height: 60%; */
  height: 48%;
  /* background: url('../assets/dialog-bg.png') no-repeat; */
  background-size: 100% 100%;
  left: 36%;
  top: 30%;
  position: fixed;
  z-index: 999;
  background: #101d35;
  box-shadow: 0 0 10px 2px #4f97f8;
  margin-left: 6px;
}
.icon-close {
  background: url("../assets/dialog/close-arrow.png") no-repeat;
  background-size: 100% 100%;
  width: 7px;
  height: 10px;
}
.vd-direction {
  background: url("../assets/dialog/icon-control.png") no-repeat;
  /* width: 142px;
  height: 142px; */
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  margin: 10px auto;
}
.vd-direction .text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vd-direction .vd-d {
  width: 24px;
  height: 24px;
}
.vd-direction .vd-top {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.vd-direction .vd-right {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.vd-direction .vd-bottom {
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}
.vd-direction .vd-left {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.count {
  width: 142px;
  margin: 0 auto;
}
.step-count {
  width: 184px;
  transform: translateX(-22px);
}
.st-wd {
  width: 35px;
}
.icon-bar {
  background: url("../assets/dialog/icon-bar.png") no-repeat;
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  right: 20px;
  top: 20px;
}
.vd-c {
  width: 0;
  transition: width 0.6s;
  overflow: hidden;
  white-space: nowrap;
}
.vd-c-c {
  width: 50%;
}
.cloudZindex {
  z-index: 999;
}
.c-d-i {
  z-index: 1000;
}
#canvas {
  display: none;
}
.recording-btn {
  width: 24px;
  height: 24px;
  border: 1px solid #86b3e9;
  border-radius: 2px;
}
.recording-red {
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 2px;
}
.recording-animate {
  animation: anim 3s linear infinite both;
}
@keyframes anim {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.pro-btn {
  border: 1px solid #86b3e9;
  padding: 4px 6px;
  border-radius: 3px;
}
.l20 {
  left: 20px;
}
.t20 {
  top: 20px;
}
</style>
