<template>
  <div class="relative">
    <a-button type="primary" @click="clickHandle()">解析地址</a-button>
    <analysis-dialog
      :visible="addVisible"
      mode="address"
      class="absolute r0 t100"
      @closeDialog="setAddVisible(false)"
    />
  </div>
</template>

<script>
import AnalysisDialog from './AnalysisDialog';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'AnalysisAdressButton',
  data() {
    return {
      // visible: false,
    };
  },
  components: {
    AnalysisDialog,
  },
  computed: {
    ...mapState({
      addVisible: (state) => state.home.addVisible,
    }),
  },
  watch: {},
  methods: {
    ...mapMutations(['setAddVisible', 'setLatVisible']),
    clickHandle() {
      this.setAddVisible(true);
      this.setLatVisible(false);
    },
  },
};
</script>

<style scoped></style>
