<template>
  <div class="absolute search-bar">
    <div class="flex items-start">
      <province-select
        v-if="$g.isDepart"
        class="m-r-sm"
        @handleProvince="selectProvince"
      />
      <a-select
        v-model="seartype"
        class="m-r-xs wd-cam"
        @change="changePotHandle"
      >
        <a-select-option value="camera">
          摄像机
        </a-select-option>
        <a-select-option value="organization">
          管辖单位
        </a-select-option>
        <a-select-option value="road">
          路线
        </a-select-option>
      </a-select>
      <div>
        <div class="flex items-center">
          <a-input-search
            placeholder="请输入搜索内容"
            :value="searchValue"
            style="width: 200px"
            @change="changeHandle"
            @search="onSearch"
            ref="searchBar"
            v-if="false"
          />
          <div class="search-bar-inner">
            <a-icon
              type="search"
              class="search-icon"
              v-show="!searchValue"
            />
            <a-input
              placeholder="请输入搜索内容"
              :value="searchValue"
              style="width: 200px"
              :class="{ focus: focusFlag }"
              @change="changeHandle"
              ref="searchBar"
            />
            <a-icon
              type="close-circle"
              class="search-del pointer"
              v-show="!!searchValue"
              @click="clearSearch"
            />
          </div>
          <a-popover placement="bottom">
            <template slot="content">
              <p>
                <!-- 您可以输入路线、桩号、摄像机名称；<br /> -->
                在快捷检索框中输入关键词后，联想推荐<br />摄像机管辖单位、路线、摄像机桩号、摄像机名称四类搜索信息。
                <!--  若您需要搜索桩号范围，请输入Kxxx~Kxxx，例如K100~K200即为搜索千米桩为100~200范围内的摄像机；<br />
            若您需要搜索组合条件，请用空格分开，例如“G2
            K100~K200”即为搜索G2路线上K100~K200范围内的摄像机；<br />
            若您搜索的摄像机桩号不存在，我们会为您自动检索上下10公里范围的摄像机。-->
              </p>
            </template>
            <i class="icon-help m-l-sm pointer"></i>
          </a-popover>
        </div>
        <template v-if="isShow">
          <div class="bg-blue-search absolute">
            <vue-scroll
              :ops="$root.scrollOpsY"
              :style="getStyle"
            >
              <div class="p-sm">
                <div class="relative ht230">
                  <div class="text-lightgray m-b-xs">
                    系统内搜索结果
                  </div>
                  <template
                    v-if="searchCameraList.length > 0"
                  >
                    <ul
                      id="test"
                      v-for="(item,
                      index) in searchCameraList"
                      :key="index"
                    >
                      <li
                        @click="changeValue(item)"
                        class="pointer m-b-sm ellipsis"
                        :title="item.cameraName"
                      >
                        <a-icon
                          type="file-sync"
                          class="m-r-xs"
                          v-show="
                            item.searchResultType === 2
                          "
                        />
                        <a-icon
                          type="bell"
                          class="m-r-xs"
                          v-show="
                            item.searchResultType === 3
                          "
                        />
                        {{ item.cameraName }}
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    <div class="text-center">
                      <a-spin
                        v-show="loading"
                        tip="加载中..."
                      ></a-spin>
                      <span v-show="!loading"
                        >没有更多了</span
                      >
                    </div>
                  </template>
                </div>
              </div>
            </vue-scroll>
            <span
              v-show="showMore"
              class="absolute full-width pointer b0 l0 more text-lightgray block text-center"
              @click="handleMore"
              >查看更多</span
            >
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import bus from '@/components/js/eventBus';
import {
  refreshAmapMarks,
  treeTrackCameraCheck,
  treeTrackProvince
} from '@/tools'
import ProvinceSelect from './ProvinceSelect'
import { CODE_OK } from '@/request/config_code'
let tempIndex = -1
export default {
  name: 'SearchBar',
  data() {
    return {
      isShow: false,
      regionCode: '',
      searchBlock: '',
      searchCameraList: [],
      total: 0,
      currPage: 1,
      // type: 'camera',
      seartype: 'camera',
      orgId:'',
      loading: false,
      focusFlag: false //是否获取焦点
    }
  },
  computed: {
    ...mapState({
      selectNode: state => state.home.selectNode,
      type: state => state.home.type,
      cameralListTree: state => state.home.cameralListTree,
      searchValue: state => state.home.searchValue,
      getCameras: state => state.home.getCameras,
      amapIns: state => state.home.amapIns,
      treeDom: state => state.home.treeDom,
      searchType: state => state.home.searchType
    }),
    getStyle() {
      return this.total > 10
        ? {
            height: 'calc(100% - 20px)'
          }
        : { height: '100%' }
    },
    showMore() {
      return (
        this.total > 10 &&
        this.searchCameraList.length < this.total
      )
    }
  },
  components: {
    ProvinceSelect
  },
  watch: {
    searchType(nv, ov) {
      if (nv != ov) {
        this.seartype = nv
        this.currPage = 1
        this.total = 0
        //新增初始化摄像机
        this.setSearchCameraId(null)
        refreshAmapMarks(this.type, this.selectNode)
      }
    },
    searchValue(nv) {
      if (nv) {
        this.focusFlag = true
      } else {
        this.focusFlag = false
      }
    }
  },
  methods: {
    ...mapActions(['getCameraLists']),
    ...mapMutations([
      'setAddressValue',
      'setLat',
      'setSearchValue',
      'setCameralListTree',
      'setSearchCameraId',
      'setSearchType',
      'setIsSearchBtn',
      'setSelectNode',
      'setSearchOk'
      // 'setSearchOrganizationId',
    ]),
    //获取焦点事件
    /*  focusHandle() {
      this.focusFlag = true;
    },
    blurHandle() {
      this.focusFlag = false;
    }, */
    changePotHandle(value) {
      // this.type = value;
      this.currPage = 1
      this.total = 0
      this.setSearchType(value)
      //清除搜索
      this.searchBlock = ''
      this.searchCameraList = []
      this.isShow = false
      this.setSearchValue('')
      this.setSearchCameraId(null)
      refreshAmapMarks(this.type, this.selectNode, true)
    },
    // 点击搜索发送
    onSearch(value) {
      clearTimeout(this.timeSearch)
      this.setAddressValue('')
      this.setLat('')
      this.clearTreeListStyle()
      this.setSearchValue(value)
      this.setIsSearchBtn(true)
      //判断是否为search操作
      this.setSearchOk(true)
      refreshAmapMarks(this.type, this.selectNode, true)
      this.timeSearch = setTimeout(() => {
        this.isShow = false
      }, 300)

      this.value = ''
    },
    changeHandle(e) {
      this.searchBlock = e.target.value
      // 防抖
      if (window.lazy) {
        clearTimeout(window.lazy)
      }
      this.currPage = 1
      this.total = 0
      this.searchCameraList = []

      window.lazy = setTimeout(() => {
        // this.getCameraLists(obj);以前
        // console.log(e.target.value);

        if (this.searchBlock) {
          this.isShow = true
          this._getCameralList(this.orgId)
        } else {
          this.isShow = false
          //恢复默认图层
          this.amapIns.setDefaultZoom()
          refreshAmapMarks(this.type, this.selectNode, true)
        }
      }, 900)
      this.setSearchValue(e.target.value)
      //清空cameraId和organizationId
      this.setSearchCameraId(null)
    },
    _getCameralList(value) {
      console.log('value', value)
      // this.orgId = value.orgnizationId
      this.loading = true
      let obj = {
        type: this.searchType,
        data: {
          searchBlock: this.searchBlock,
          regionCode: this.regionCode,
          highwayFlag: (!value && this.searchType == 'camera') ? '1' :'',
          organizationId: value ? value : ''
          // organizationId: value.orgId
        },

        params: { currPage: this.currPage, pageSize: 10 }
      }
      this.$api.home.getHomeSearchList(obj).then(res => {
        if (res.code === CODE_OK) {
          this.total = res.total
          if (this.currPage === 1) {
            this.searchCameraList = res.data
          } else {
            this.searchCameraList = [
              ...this.searchCameraList,
              ...res.data
            ]
          }
          this.loading = false
          /*  if (this.searchCameraList.length < this.total) {
            this.currPage++;
          } */
          // 展示下拉菜单
          /* if (this.searchBlock) {
            this.isShow = true;
          } else {
            this.isShow = false;
            
            refreshAmapMarks(this.type, this.selectNode, true);
          }fixsxy */
        }
      })
    },
    /* initCamera() {
      let copyCamera = JSON.parse(JSON.stringify(this.cameralListTree));
      this.selectNode.check = false;
      let cameraList = treeTrackCameraCheck(copyCamera, this.selectNode);
      this.setCameralListTree(cameraList);
    }, */
    handleMore() {
      if (this.searchCameraList.length < this.total) {
        this.currPage++
        this._getCameralList()
      }
    },
    // 点击搜索具体哪一路

    changeValue(item) {
      this.setSearchValue(item.cameraName)
      /*    console.error(item); */
      /* this.amapIns.addPoint(item); */
      /* if (item.searchResultType === 1) {
        this.setSearchCameraId(item.cameraId);
      } else {
        this.setSearchCameraId(item.organizationId);
      }fix以前 */
      this.setSearchCameraId(item)
      this.setIsSearchBtn(false)
      this.setSearchOk(true)
      if (this.seartype == 'camera') {
        this.amapIns.addPointAndPlay(item)
      } else {
        refreshAmapMarks(this.type, this.selectNode)
      }

      this.isShow = false
    },
    //清除搜索条件
    clearSearch() {
      this.setSearchValue('')
      this.isShow = false
      this.$refs.searchBar && this.$refs.searchBar.focus()
      this.focusFlag = true
      //去除高亮点位（请加去除高亮点位逻辑）
    },
    clearTreeListStyle() {
      let copyCamera = JSON.parse(
        JSON.stringify(this.cameralListTree)
      )
      let cameraList = treeTrackCameraCheck(copyCamera)
      this.setCameralListTree(cameraList)
    },
    setTreeListStyle(id) {
      let copyCamera = JSON.parse(
        JSON.stringify(this.cameralListTree)
      )
      let cameraListObj = treeTrackProvince(copyCamera, id)
      let { cameraList, curIndex } = cameraListObj
      if (curIndex != -1) {
        tempIndex = curIndex
      }
      // console.log('aaaaaaa', cameraList);
      this.setCameralListTree(cameraList)
      let dom = null
      let child = document.getElementById('treeDomWrap')
        .children[tempIndex]
      if (child) {
        dom = child.querySelector(
          '.ant-tree-node-content-wrapper'
        )
      }

      /* if (id !== '' && cameraList[curIndex].check) {
        dom && dom.click();
      } */

      if (tempIndex != -1) {
        dom && dom.click()
      }

      /*  if (id !== '') {
        dom && dom.click();
      }else{

      } */
      /* if(id == ''){
        dom && dom.click();
      } */
      /*  dom && dom.click(); */
    },
    selectProvince(id) {
      this.regionCode = id
      this.setTreeListStyle(id)
    }
  },

  created() {
    process.env.NODE_ENV === 'development' &&
      (window.trafficSearch_vue = this)
  },
  mounted() {
    bus.$on('search', (e) => {
      const { organizationId } = e
      this.orgId = e.orgnizationId
      console.log('this.orgId2', organizationId, this.orgId)
      this._getCameralList(this.orgId)
    })
  },
  activated() {
    this.seartype = 'camera'
    this.setSearchValue('')
  },
  destroyed() {
    clearTimeout(this.timeSearch)
    window.lazy && clearTimeout(window.lazy)
  }
}
</script>

<style scoped>
.search-bar {
  z-index: 998;
}
.search-bar >>> .ant-input {
  background-color: #08224a;
  border: 1px solid #4c658a;
  /* color: #86b3e9; */
  color: #f0f0f0;
  padding-left: 20px;
}
.search-bar >>> .ant-input.focus {
  padding-left: 5px;
  padding-right: 25px;
}
.search-bar >>> .ant-input::-webkit-input-placeholder,
.search-bar >>> .ant-select {
  color: #f0f0f0;
}
.search-bar >>> .ant-input-affix-wrapper .ant-input-suffix {
  padding-left: 10px;
  border-left: 1px solid #4c658a;
}
.search-bar >>> .ant-input-search-icon {
  color: #86b3e9;
}
.icon-help {
  display: inline-block;
  background: url('../../../assets/home/help.png') no-repeat;
  width: 21px;
  height: 21px;
  background-size: 100% 100%;
}
.bg-blue-search {
  /* background: rgba(33, 55, 88, 0.9); */
  background: rgba(33, 55, 88, 1);
  height: 250px;
  min-width: 200px;
}
.ht230 {
  height: 230px;
}
.wd-cam {
  width: 100px;
  background: #08224a;
}
.search-bar-inner {
  position: relative;
  color: #fff;
}
.search-bar-inner .search-icon {
  position: absolute;
  left: 5px;
  top: 9px;
  z-index: 2;
}
.search-bar-inner .search-del {
  position: absolute;
  right: 5px;
  top: 9px;
  z-index: 2;
}
</style>
