<template>
  <div class="full-width full-height map-container">
    <map-container :showButton="false" />
  </div>
</template>

<script>
import MapContainer from '@/views/Home/components/HomeMain';
export default {
  name: 'MapCom',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  components: {
    MapContainer,
  },
};
</script>

<style scoped>
.map-container .home-inner {
  height: 100% !important;
}
</style>
