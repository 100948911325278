var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"traffic-map-box",staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticStyle:{"height":"100%","width":"100%","overflow":"hidden"},attrs:{"id":"mapcontainer"}}),_c('video-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag",value:(_vm.dragHandle),expression:"dragHandle"}],ref:"amapPlayer",attrs:{"cameraId":_vm.videoCameraId,"cameraNum":_vm.videoCameraNum,"visible":_vm.cameraMarkVisable},on:{"update:visible":function($event){_vm.cameraMarkVisable=$event},"close":_vm.closeVideoDialog}}),_c('drag',{ref:"dragMarkerGhost",attrs:{"id":"dragMarkerGhost","transfer-data":_vm.chosedMarkData,"draggable":true,"image":[
        'zsy_qjsxj_00001',
        'zsy_qjsxj_00002',
        'zsy_qjsxj_00003',
        'zsy_qjsxj_hall_01',
        'zsy_qjsxj_hall_02'
      ].indexOf(_vm.chosedMarkData.cameraNum) != -1
        ? _vm.cameraPanoramaImgUrl
        : _vm.cameraImgUrl[_vm.chosedMarkData.classify]},on:{"dragstart":_vm.dragMarkerGhostDragstart,"dragend":_vm.dragMarkerGhostDragend}}),_c('modify-lat',{ref:"modifyLat",attrs:{"visible":_vm.showLatVisible,"cameraInfo":_vm.rightClickInfo},on:{"update:visible":function($event){_vm.showLatVisible=$event},"restorePos":_vm.restorePostInfo,"restoreOldPostion":_vm.restoreOldPostion}}),_c('confirm-lat',{ref:"confirmLat",attrs:{"visible":_vm.confirmLatVisible,"config":_vm.configLatConfig},on:{"cancelConfirm":_vm.cancelConfirmHandle,"submitHandle":_vm.submitConfirmHandle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }