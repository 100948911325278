<template>
  <div class="relative">
    <a-button type="primary" @click="clickHandle()">解析经纬度</a-button>
    <analysis-dialog
      :visible="latVisible"
      mode="lat"
      class="absolute r0 t100"
      @closeDialog="setLatVisible(false)"
    />
  </div>
</template>

<script>
import AnalysisDialog from './AnalysisDialog';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'AnalysisAdressButton',
  data() {
    return {
      // visible: false,
    };
  },
  components: {
    AnalysisDialog,
  },
  computed: {
    ...mapState({
      latVisible: (state) => state.home.latVisible,
    }),
  },
  watch: {},
  methods: {
    ...mapMutations(['setAddVisible', 'setLatVisible']),
    clickHandle() {
      this.setAddVisible(false);
      this.setLatVisible(true);
    },
  },
};
</script>

<style scoped></style>
