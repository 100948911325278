<template>
  <div>
    <a-modal
      title="收藏视频"
      :visible="visible"
      width="24%"
      :centered="true"
      :destroyOnClose="true"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="flex items-center">
        收藏至：<a-select v-model="selectValue" style="width:160px">
          <a-select-option
            :value="folder.folderId"
            v-for="folder in homeFolder"
            :key="folder.folderId"
          >
            {{ folder.folderName }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'CollectDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmLoading: false,
      selectValue: '',
    };
  },
  computed: {
    ...mapState({
      homeFolder: (state) => state.home.homeFolder,
    }),
  },
  watch: {
    visible(nv) {
      if (nv) {
        this.initData();
      }
    },
  },
  methods: {
    initData() {
      this.getHomeFolder().then(() => {
        this.selectValue = this.homeFolder.filter(
          (item) => item.whetherDefaultFolder === 1
        )[0].folderId;
      });
    },
    handleCancel() {
      this.selectValue = '';
      this.$emit('closeCollect');
    },
    handleOk() {
      this.$emit('sureCollect', this.selectValue);
      this.selectValue = '';
    },
    ...mapActions(['getHomeFolder']),
  },
};
</script>

<style scoped></style>
