<template>
  <div class="flex img-wrap   items-center">
    <span
      title="摄像机位置类型"
      class="all-sp pointer"
      :class="{ current: isAll }"
      @click="moreHandle"
      @mouseenter="mouseEnterHandle"
      @mouseleave="mouseLeaveHandle"
    ></span>
    <transition name="animate">
      <div
        class="flex img-list justify-around  items-center"
        v-show="showTab"
        @mouseenter="mouseEnterHandle"
        @mouseleave="mouseLeaveHandle"
      >
        <a-tooltip placement="top" v-for="item in list" :key="item.id">
          <template slot="title">
            <span>{{ item.title }}</span>
          </template>
          <i
            :class="[
              'pointer icon-img',
              item.name,
              { current: botClassify.includes(item.value) },
            ]"
            @click="selectHandle(item)"
          />
        </a-tooltip>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { refreshAmapMarks } from '@/tools';
export default {
  name: 'HomeBotSubMenu',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // curIndex: 0,
      // isAll: true,
      firstLoad: true,
      showTab: false,
    };
  },
  computed: {
    ...mapState({
      botClassify: state => state.home.botClassify,
      botClassifyCopy: state => state.home.botClassifyCopy,
      selectNode: state => state.home.selectNode,
      type: state => state.home.type,
      isAll: state => state.home.isAll,
    }),
  },
  watch: {},
  methods: {
    ...mapMutations(['setBotClassify', 'setIsAll']),
    mouseEnterHandle() {
      this.showTab = true;
    },
    mouseLeaveHandle() {
      this.showTab = false;
    },
    moreHandle() {
      let isAll = this.isAll ? false : true;
      /*  if (this.firstLoad) {
        this.copyBotClassify = this.botClassify.slice();
        this.firstLoad = false;
      } */
      this.copyBotClassify = this.botClassifyCopy;

      this.setBotClassify(isAll ? this.copyBotClassify : []);
      this.setIsAll(isAll);
      refreshAmapMarks(this.type, this.selectNode);
    },
    selectHandle(item) {
      // this.curIndex = index;
      // this.setBotClassify(item.value);
     /*  if (this.isAll) {
        return;
      } fixsxy 这里需求有变更*/
      /* if (this.botClassify.includes(item.value)) {
        let index = this.botClassify.indexOf(item.value);
        this.botClassify.splice(index, 1);
      } else {
        this.botClassify.push(item.value);
      } fixsxy多个选择不要了*/
      let arr = [];
      arr[0] = item.value; //单个选择
      //单个选择总的选择为false
      this.setIsAll(false);
      this.setBotClassify(arr);
      // this.$emit('selectBotSubTab');
      refreshAmapMarks(this.type, this.selectNode);
    },
  },
};
</script>

<style scoped>
.all-sp {
  background: url('../../../assets/home/icon-all.png') no-repeat;
  width: 48px;
  height: 42px;
  background-size: 100% 100%;
}
.all-sp.current {
  background: url('../../../assets/home/icon-all-on.png') no-repeat;
  background-size: 100% 100%;
}
.img-wrap {
  margin: 15px 0 0;
  position: relative;
  z-index: 999;
}
.img-list {
  height: 30px;
  background: rgba(16, 36, 69, 0.5);
  border: 1px transparent solid;
  border-image: linear-gradient(
      to right,
      rgba(16, 36, 69, 1),
      rgba(57, 109, 178, 1),
      rgba(16, 36, 69, 1)
    )
    1 0;
  width: 300px;
}
.img-list img {
  width: 22px;
  height: 22px;
}
.icon-img {
  width: 22px;
  height: 22px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.icon-sm-0 {
  background-image: url('../../../assets/home/icon-sm-0.png');
}
.icon-sm-1 {
  background-image: url('../../../assets/home/icon-sm-1.png');
}
.icon-sm-2 {
  background-image: url('../../../assets/home/icon-sm-2.png');
}
.icon-sm-3 {
  background-image: url('../../../assets/home/icon-sm-3.png');
}
.icon-sm-4 {
  background-image: url('../../../assets/home/icon-sm-4.png');
}
.icon-sm-5 {
  background-image: url('../../../assets/home/icon-sm-5.png');
}
.icon-sm-6 {
  background-image: url('../../../assets/home/icon-sm-6.png');
}
.icon-sm-7 {
  background-image: url('../../../assets/home/icon-sm-7.png');
}
.icon-sm-8 {
  background-image: url('../../../assets/home/icon-sm-8.png');
}

.icon-sm-0.current {
  background-image: url('../../../assets/home/icon-sm-0-on.png');
}

.icon-sm-1.current {
  background-image: url('../../../assets/home/icon-sm-1-on.png');
}

.icon-sm-2.current {
  background-image: url('../../../assets/home/icon-sm-2-on.png');
}
.icon-sm-3:hover,
.icon-sm-3.current {
  background-image: url('../../../assets/home/icon-sm-3-on.png');
}

.icon-sm-4.current {
  background-image: url('../../../assets/home/icon-sm-4-on.png');
}

.icon-sm-5.current {
  background-image: url('../../../assets/home/icon-sm-5-on.png');
}

.icon-sm-6.current {
  background-image: url('../../../assets/home/icon-sm-6-on.png');
}

.icon-sm-7.current {
  background-image: url('../../../assets/home/icon-sm-7-on.png');
}

.icon-sm-8.current {
  background-image: url('../../../assets/home/icon-sm-8-on.png');
}
.animate-enter-active,
.animate-leave-active {
  transition: all 0.3s;
}
.animate-enter,
.animate-leave-to {
  width: 0;
  opacity: 0;
}
</style>
