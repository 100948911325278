<template>
  <div class="absolute traffic-bot">
    <div
      v-if="hasPermission(1092101110)"
      class="flex filter-status items-center m-l-sm"
    >
      <!--  <span
        :class="[
          'sp-item online   p-w-sm m-r-sm pointer',
          { current: onlineStatus.includes('1') },
        ]"
        @click="selectHandle('1')"
        ><i class="m-r-xs"></i
        > {{ chartOnline }}</span
      >
      
      <span
        :class="[
          `sp-item ${
            $g.treeDefinate ? 'yunOffline' : 'offline'
          } m-r-sm  p-w-sm pointer`,
          { current: onlineStatus.includes('0') },
        ]"
        @click="selectHandle('0')"
        ><i class="m-r-xs"></i
        > {{ chartOffline }}</span
      >
      <span
        :class="[
          'sp-item error p-w-sm pointer',
          { current: onlineStatus.includes('2') },
        ]"
        v-if="!$g.isHideException"
        @click="selectHandle('2')"
        ><i class="m-r-xs"></i
        > {{ chartError }}</span
      > -->
      <span class="online flex-one text-center"
        ><a-checkbox
          :checked="getOnline"
          @change="selectHandle('1')"
          class="m-r-xs"
        ></a-checkbox
        >在线</span
      >
      <span
        :class="[
          `offline flex-one text-center   ${
            $g.treeDefinate ? 'yunOffline' : 'offline'
          }`,
        ]"
        ><a-checkbox
          :checked="getOffline"
          @change="selectHandle('0')"
          class="m-r-xs"
        ></a-checkbox
        >离线</span
      >
      <span class="error flex-one text-center" v-if="!$g.isHideException"
        ><a-checkbox
          :checked="getError"
          @change="selectHandle('2')"
          class="m-r-xs"
        ></a-checkbox
        >故障</span
      >
    </div>
    <template>
       <home-bot-sub-menu v-if="hasPermission(1092101111) && isShowClassify" :list="subMenuList" />
       <div class="plain-border" v-else></div>
    </template>
    <!-- <home-left-sub-menu :list="subMenuList" /> -->
  </div>
</template>

<script>
import HomeBotSubMenu from './HomeBotSubMenu';
import { mapState, mapMutations } from 'vuex';
import { refreshAmapMarks } from '@/tools';
export default {
  name: 'TrafficBottom',
  data() {
    return {
      subMenuList: [
        {
          id: 0,
          value: '1',
          name: 'icon-sm-0',
          title: '道路沿线',
          src: require('@/assets/home/icon-sm-0.png'),
        },
        {
          id: 1,
          value: '2',
          name: 'icon-sm-1',
          title: '桥梁',
          src: require('@/assets/home/icon-sm-1.png'),
        },
        {
          id: 2,
          value: '3',
          name: 'icon-sm-2',
          title: '隧道',
          src: require('@/assets/home/icon-sm-2.png'),
        },
        /*   {
          id: 7,
          value: '8',
          name: 'icon-sm-7',
          title: '应急物资储备中心',
          src: require('@/assets/home/icon-sm-7.png'),
        }, */
        {
          id: 3,
          value: '4',
          name: 'icon-sm-7',
          title: '收费广场',
          src: require('@/assets/home/icon-sm-7.png'),
        },
        {
          id: 4,
          value: '5',
          name: 'icon-sm-3',
          title: '收费站',
          src: require('@/assets/home/icon-sm-3.png'),
        },
        {
          id: 5,
          value: '6',
          name: 'icon-sm-4',
          title: '服务区',
          src: require('@/assets/home/icon-sm-4.png'),
        },
        {
          id: 6,
          value: '7',
          name: 'icon-sm-5',
          title: 'ETC门架',
          src: require('@/assets/home/icon-sm-5.png'),
        },
        {
          id: 7,
          value: '8',
          name: 'icon-sm-6',
          title: '应急指挥车',
          src: require('@/assets/home/icon-sm-6.png'),
        },

        {
          id: 8,
          value: '0',
          name: 'icon-sm-8',
          title: '其他',
          src: require('@/assets/home/icon-sm-8.png'),
        },
      ],
      onlineSta: false,
      offlineSta: false,
      errorSta: false,
    };
  },
  components: {
    HomeBotSubMenu,
  },
  computed: {
    ...mapState({
      statusCount: state => state.home.statusCount,
      onlineStatus: state => state.home.onlineStatus,
      selectNode: state => state.home.selectNode,
      type: state => state.home.type,
      chartData: state => state.home.chartData,
      isShowClassify: state => state.home.isShowClassify,
    }),
    getOnline() {
      return this.onlineStatus.includes('1');
    },
    getOffline() {
      return this.onlineStatus.includes('0');
    },
    getError() {
      return this.onlineStatus.includes('2');
    },
    chartFormateData() {
      let total = 0,
        offline = 0,
        online = 0,
        inerror = 0;
      this.chartData.forEach(vo => {
        total += vo.total;
        offline += vo.offline;
        online += vo.online;
        inerror += vo.inerror;
      });
      return { total, offline, online, inerror };
    },
    chartOnline() {
      let online = 0;
      this.chartData.forEach(vo => {
        online += vo.online;
      });
      return online;
    },
    chartOffline() {
      let offline = 0;
      this.chartData.forEach(vo => {
        offline += vo.offline;
      });
      return offline;
    },
    chartError() {
      let inerror = 0;
      this.chartData.forEach(vo => {
        inerror += vo.inerror;
      });
      return inerror;
    },
  },
  watch: {},
  methods: {
    ...mapMutations(['setOnlineStatus']),
    selectHandle(value) {
      if (this.onlineStatus.includes(value)) {
        let index = this.onlineStatus.indexOf(value);
        this.onlineStatus.splice(index, 1);
      } else {
        this.onlineStatus.push(value);
      }
      this.setOnlineStatus(this.onlineStatus);
      refreshAmapMarks(this.type, this.selectNode);
    },
  },
};
</script>

<style scoped>
.traffic-bot {
  bottom: 20px;
  left: 10px;
}
.sp-item {
  height: 30px;
  line-height: 30px;
  background: #0b1429;
  border: 1px solid #7a7a7a;
  border-radius: 6px;
  width: 76px;
  /* width: 60px; */
  white-space: nowrap;
  color: #fff;
  text-align: center;
}
.sp-item.error {
  border-color: rgba(255, 111, 87, 1);
  background: rgba(11, 20, 41, 1);
}
.sp-item.online {
  border-color: rgba(120, 177, 249, 1);

  background: rgba(11, 20, 41, 1);
}
.sp-item.offline {
  border-color: rgba(161, 161, 161, 1);
  background: rgba(11, 20, 41, 1);
}
.sp-item.yunOffline {
  border-color: #ff6d55;
  background: rgba(11, 20, 41, 1);
}
.sp-item.online.current {
  /* background: #bcdaff; */
  /* border-color: #bcdaff; */
  border-color: rgba(120, 177, 249, 1);

  background: rgba(120, 177, 249, 1);
  color: #0e1e3f;
  font-weight: bold;
}
.sp-item.error.current {
  border-color: rgba(255, 111, 87, 1);
  background: rgba(255, 111, 87, 1);
  color: #5b2f2a;
  font-weight: bold;
}
.sp-item.offline.current {
  border-color: rgba(161, 161, 161, 1);
  background: rgba(161, 161, 161, 1);
  color: #4c4140;
  font-weight: bold;
}
.sp-item.yunOffline.current {
  border-color: #ff6d55;
  background: #ff6d55;
  color: #4c4140;
  font-weight: bold;
}
.sp-item i {
  background: url('../../../assets/home/cameral-default.png') no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
  background-size: 100% 100%;
  vertical-align: -1px;
}
.sp-item.online.current i {
  background: url('../../../assets/home/cameral-current.png') no-repeat;
  background-size: 100% 100%;
}
.sp-item.error.current i,
.sp-item.yunOffline.current i {
  background: url('../../../assets/home/c-error.png') no-repeat;
  background-size: 100% 100%;
}
.sp-item.offline.current i {
  background: url('../../../assets/home/c-offline.png') no-repeat;
  background-size: 100% 100%;
}
.online {
  color: #78b1f9;
}
.offline {
  color: rgba(143, 143, 143, 1);
}
.error {
  color: rgba(255, 91, 74, 1);
}
.yunOffline {
  color: #ff6d55;
  background: none !important;
}
.filter-status {
  width: 200px;
  height: 36px;
  background: rgba(44, 61, 132, 0);
  border: 1px solid rgba(81, 144, 204, 1);
  box-shadow: 0 0 10px 2px rgb(23, 122, 214) inset;
  border-radius: 18px;
}
.filter-status >>> .ant-checkbox-inner {
  background: rgba(22, 39, 65, 1);
  border-color: rgba(61, 123, 204, 1);
}
.filter-status >>> .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(86, 155, 246, 1);
}
.plain-border{
  height: 57px;
}
</style>
