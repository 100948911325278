import { mapMutations, mapState } from 'vuex'
/*import aliPlayer from '@/components/AliPlayer'*/
// import amapPlayer from '@/components/AmapPlayerDialog';
import VideoDialog from '@/components/VideoDialog'
import { Drag } from 'vue-drag-drop'
import ModifyLat from '@/components/ModifyLat'
import ConfirmLat from '@/components/ConfirmLat'
import { refreshAmapMarks } from '@/tools'
import { CODE_OK } from '@/request/config_code'
//import axios from "axios";

const AMap = window.AMap
const AMapUI = window.AMapUI
/* let tempResolution = -1;
let tempCamaraInfo = null; */
export default {
  name: 'traffic-amap',
  components: {
    //aliPlayer,
    // amapPlayer,
    VideoDialog,
    Drag,
    ModifyLat,
    ConfirmLat
  },
  props: {
    baseTopAdcodes: {
      type: Number,
      default: 100000
    },
    baseTopAdcodesName: {
      type: String,
      default: '中国'
    },
    loadCurArea: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let vm = this
    let googleLayer = new AMap.TileLayer.Satellite({
      //zooms:[15,24],    //可见级别
      getTileUrl:
        'http://mt2.google.cn/vt/lyrs=s@817&hl=zh-CN&gl=cn&x=[x]&y=[y]&z=[z]&s=Galil',
      zIndex: 20,
      //tileUrl : 'http://www.google.cn/maps/vt?lyrs=s@817&gl=cn&x=[x]&y=[y]&z=[z]',
      opacity: 1
    })
    let satellite = new AMap.TileLayer.Satellite()
    let roadNet = new AMap.TileLayer.RoadNet({
      zIndex: 1
    })
    let trafficLayer = new AMap.TileLayer.Traffic({
      zIndex: 2
    })

    return {
      googleLayer,
      satellite,
      roadNet,
      trafficLayer,
      mapInstance: null,
      districtExplorer: null,
      currentAreaNode: null,
      $tipMarkerContent: null,
      tipMarker: null,
      pointSimplifierIns: null,

      /*cameraImgUrl                     : require('@/assets/camera.gif'),
      cameraOfflineImgUrl              : require('@/assets/camera_offline.gif'),
      cameraErrorImgUrl                : require('@/assets/camera_error.gif'),*/

      /*cameraImgUrl                     : require('@/assets/map/normal.png'),
      cameraOfflineImgUrl              : require('@/assets/map/off-line.png'),
      cameraErrorImgUrl                : require('@/assets/map/abnormal.png'),
      cameraImgBtnUrl                  : require('@/assets/map/normal-btn.png'),
      cameraOfflineImgBtnUrl           : require('@/assets/map/off-line-btn.png'),
      cameraErrorImgBtnUrl             : require('@/assets/map/abnormal-btn.png'),*/
      //'1','2','3','7','5','6'
      //摄像机选中图标
      cameraGifUrl: {
        '1': require('@/assets/traffic_map/icon/camera-gif.gif'),
        '2': require('@/assets/traffic_map/icon/camera-gif-2.gif'),
        '3': require('@/assets/traffic_map/icon/camera-gif-3.gif'),
        '4': require('@/assets/traffic_map/icon/camera-gif-5.gif'),
        '5': require('@/assets/traffic_map/icon/camera-gif-5.gif'),
        '6': require('@/assets/traffic_map/icon/camera-gif-6.gif'),
        '7': require('@/assets/traffic_map/icon/camera-gif-7.gif')
      },
      //摄像机正常图标
      cameraImgUrl: {
        '0': require('@/assets/traffic_map/icon/newIcon/other-online.png'),
        '1': require('@/assets/traffic_map/icon/newIcon/road-online.png'),
        '2': require('@/assets/traffic_map/icon/newIcon/bridge-online.png'),
        '3': require('@/assets/traffic_map/icon/newIcon/tunnel-online.png'),
        '5': require('@/assets/traffic_map/icon/newIcon/toll-online.png'),
        '6': require('@/assets/traffic_map/icon/newIcon/service-online.png'),
        '7': require('@/assets/traffic_map/icon/newIcon/etc-online.png'),
        '8': require('@/assets/traffic_map/icon/newIcon/car-online.png'),
        '4': require('@/assets/traffic_map/icon/newIcon/reserve-online.png'),
        '9': require('@/assets/traffic_map/icon/newIcon/traffic-online.png'),
        '10': require('@/assets/traffic_map/icon/newIcon/bstation-online.png'),
        '11': require('@/assets/traffic_map/icon/newIcon/gangkou-online.png'),
        '12': require('@/assets/traffic_map/icon/newIcon/kouan-online.png'),
        '13': require('@/assets/traffic_map/icon/newIcon/bus-online.png'),
        '14': require('@/assets/traffic_map/icon/newIcon/youzheng-online.png'),
        '15': require('@/assets/traffic_map/icon/newIcon/tielu-online.png'),
        '16': require('@/assets/traffic_map/icon/newIcon/plane-online.png')
      }, //require('@/assets/images/traffic_map/icon/camera.png'),
      //摄像机离线图标
      cameraOfflineImgUrl: {
        '0': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'other-yunnan-offline'
            : 'other-offline'
        }.png`),
        '1': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'road-yunnan-offline'
            : 'road-offline'
        }.png`),
        '2': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'bridge-yunnan-offline'
            : 'bridge-offline'
        }.png`),
        '3': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'tunnel-yunnan-offline'
            : 'tunnel-offline'
        }.png`),
        '5': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'toll-yunnan-offline'
            : 'toll-offline'
        }.png`),
        '6': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'service-yunnan-offline'
            : 'service-offline'
        }.png`),
        '7': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'etc-yunnan-offline'
            : 'etc-offline'
        }.png`),
        '8': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'car-yunnan-offline'
            : 'car-offline'
        }.png`),
        '4': require(`@/assets/traffic_map/icon/newIcon/${
          vm.$g.treeDefinate
            ? 'reserve-yunnan-offline'
            : 'reserve-offline'
        }.png`),
        '9': require('@/assets/traffic_map/icon/newIcon/traffic-error.png'),
        '10': require('@/assets/traffic_map/icon/newIcon/bstation-error.png'),
        '11': require('@/assets/traffic_map/icon/newIcon/gangkou-error.png'),
        '12': require('@/assets/traffic_map/icon/newIcon/kouan-error.png'),
        '13': require('@/assets/traffic_map/icon/newIcon/bus-error.png'),
        '14': require('@/assets/traffic_map/icon/newIcon/youzheng-error.png'),
        '15': require('@/assets/traffic_map/icon/newIcon/tielu-error.png'),
        '16': require('@/assets/traffic_map/icon/newIcon/plane-error.png')
      }, //require('@/assets/images/traffic_map/icon/camera-offline.png'),
      //摄像机异常图标
      cameraErrorImgUrl: {
        '0': require('@/assets/traffic_map/icon/newIcon/other-error.png'),
        '1': require('@/assets/traffic_map/icon/newIcon/road-error.png'),
        '2': require('@/assets/traffic_map/icon/newIcon/bridge-error.png'),
        '3': require('@/assets/traffic_map/icon/newIcon/tunnel-error.png'),
        '5': require('@/assets/traffic_map/icon/newIcon/toll-error.png'),
        '6': require('@/assets/traffic_map/icon/newIcon/service-error.png'),
        '7': require('@/assets/traffic_map/icon/newIcon/etc-error.png'),
        '8': require('@/assets/traffic_map/icon/newIcon/car-error.png'),
        '4': require('@/assets/traffic_map/icon/newIcon/reserve-error.png'),
        '9': require('@/assets/traffic_map/icon/newIcon/traffic-error.png'),
        '10': require('@/assets/traffic_map/icon/newIcon/bstation-error.png'),
        '11': require('@/assets/traffic_map/icon/newIcon/gangkou-error.png'),
        '12': require('@/assets/traffic_map/icon/newIcon/kouan-error.png'),
        '13': require('@/assets/traffic_map/icon/newIcon/bus-error.png'),
        '14': require('@/assets/traffic_map/icon/newIcon/youzheng-error.png'),
        '15': require('@/assets/traffic_map/icon/newIcon/tielu-error.png'),
        '16': require('@/assets/traffic_map/icon/newIcon/plane-error.png')
      }, //require('@/assets/images/traffic_map/icon/camera-error.png'),
      cameraPanoramaImgUrl: require('@/assets/traffic_map/icon/camera-panorama.png'),
      cameraPanoramaGifUrl: require('@/assets/traffic_map/icon/camera-panorama-gif.gif'),

      cameraIconWidth: 18, //35,
      cameraIconHeight: 18, //40*88/76
      cameraIconWidth2: 40,
      cameraIconHeight2: 40,
      cameraVideoInfo: {},
      videoUrl: '',
      currentPlayCamera: {},
      cameraInfoWindow: null,

      cameraMarkVisable: false,
      cameraImagesVisable: false,
      cameraInfoVisable: false,
      cameraInfoEditVisable: false,

      videoDialogElement: null,

      //baseTopAdcodes                   : 100000,

      resolutionValue: '20', //视频清晰度

      currentMarkers: [],
      currentPosMarker: {},
      backMark: null, //当前选中或之前选中的mark点
      chosedMark: null,
      chosedMarkData: {},
      dragMarkerGhostStyle: {},
      infoWindow: null,
      editCameraInfoSign: false, //用户修改摄像机权限

      mapLayerType: 'base',
      eventClickMarker: null,
      currPolyline: null,

      renderPolygon: null,
      currentVideoMarker: null,
      videoCameraId: '',
      videoCameraNum: '',
      mark_point: require('@/assets/traffic_map/point-qf.png'),
      mark_video_point: require('@/assets/traffic_map/icon/camera-gif-5.gif'),
      currentPointMarker: null,

      zoomFlag: false,
      moveFlag: false,
      showLatVisible: false, //显示位置弹窗
      confirmLatVisible: false, //确定纠偏弹窗
      configLatConfig: {
        isSubBtnShow: true
      }, // 确定纠偏弹窗配置
      rightClickInfo: {}, //右击悬浮物坐标信息
      modifyPostion: {}, //新位置
      currentPointVideoMarker: null, //播放和定位
      blockRouteMarkers: [], //拥堵路段marker
      currPolylines: [],
      blockImgUrl: require('@/assets/traffic_map/icon/newIcon/block-icon.png')
    }
  },
  computed: {
    /* videoPlayer() {
          return this.$refs.videoPlayer.player
      }, */
    ...mapState({
      selectNode: (state) => state.home.selectNode,
      type: (state) => state.home.type
    })
  },
  watch: {
    showLatVisible(nv, ov) {
      if (!nv && nv !== ov) {
        //清除当前选中覆盖物
        this.chosedMark.show()
        if (this.$refs.dragMarkerGhost) {
          this.$refs.dragMarkerGhost.$el.style.top = '-50px'
          this.$refs.dragMarkerGhost.$el.style.left =
            '-50px'
        }
        this.backMark && this.removeMarker(this.backMark)
      }
    }
  },
  beforeMount() {
    //加载点位marker事件监听
    this.loadDefaultAmapEvent()
  },
  mounted() {
    this.mapInit()
    document.oncontextmenu = function() {
      return false
    }
  },
  methods: {
    /*...mapActions([
        'getCameraPlayUrl',
    ]),*/
    ...mapMutations([
      'setSelectCameraIndex',
      'setLatResult',
      'setDragMove',
      'setDefaultZoom'
    ]),
    addBlockRoute(list) {
      if (!list.length) {
        return
      }
      if (this.blockRouteMarkers.length) {
        this.blockRouteMarkers.forEach((item) => {
          this.mapInstance.remove(item)
        })
      }
      list.forEach((item, index) => {
        let pos = item.centerPointWKT
          .replace(/^POINT\((.*)\)/g, '$1')
          .split(' ')
        let labelContent = `<div class='labelContent'>${item.roadName}</div>`
        let imageUrl = this.blockImgUrl
        let labelOffset = new AMap.Pixel(0, 25)
        let icon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(20, 20),
          // 图标的取图地址
          image: imageUrl,
          // 图标所用图片大小
          imageSize: new AMap.Size(20, 20)
        })
        let marker = new AMap.Marker({
          position: pos,
          icon: icon,
          anchor: 'center', //设置锚点
          zIndex: 999,
          label: {
            direction: 'center',
            content: labelContent,
            offset: labelOffset
          }
        })
        this.mapInstance.add(marker)
        this.blockRouteMarkers[index] = marker
        marker.on('click', () => {
          const listLinkInfoState = item.listLinkInfoState
          console.log(item, 'item111222')
          let lineArr = []
          listLinkInfoState.forEach((item) => {
            let arr = item['lngLats'].split(';')
            let lngArr = []
            arr.forEach((ar) => {
              let lng = ar.split(',')
              lngArr.push(lng)
            })
            // lineArr = lineArr.concat(lngArr);
            lineArr.push(lngArr)
          })

          this.drawMulLine(lineArr, pos)
        })
      })
    },
    //删除拥堵marker
    removeBlockRouteMarker() {
      if (this.blockRouteMarker) {
        this.blockRouteMarker.hide()
      }
    },
    //提交位置纠偏
    submitConfirmHandle() {
      // console.log(this.rightClickInfo,this.modifyPostion['lng'],this.modifyPostion['lat']);
      let params = {
        correctLongitude: this.modifyPostion['lng'],
        correctLatitude: this.modifyPostion['lat'],
        cameraId: this.rightClickInfo.cameraId
      }
      this.$api.home
        .modifyCoordinates(params)
        .then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('位置纠偏成功！')
            this.cancelConfirmHandle()
          }
        })
    },
    //一键复位
    restoreOldPostion() {
      let params = {
        cameraId: this.rightClickInfo.cameraId
      }
      this.$api.home
        .resetCoordinates(params)
        .then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('一键复位成功！')
            this.showLatVisible = false
            //清除当前选中覆盖物
            this.backMark &&
              this.removeMarker(this.backMark)
            refreshAmapMarks(this.type, this.selectNode)
          }
        })
    },
    //位置纠偏
    restorePostInfo() {
      //清除地图所有覆盖物
      this.clearMapMarker()
      this.showLatVisible = false
      //将拖动视频物移除屏幕
      this.$refs.dragMarkerGhost.$el.style.top = '-100px'
      this.$refs.dragMarkerGhost.$el.style.left = '-100px'

      //显示纠偏点位
      this.showModifyPos()
    },
    //显示修改位置
    showModifyPos() {
      const _this = this

      AMapUI.loadUI(['misc/PositionPicker'], function(
        PositionPicker
      ) {
        _this.positionPicker = new PositionPicker({
          mode: 'dragMap',
          map: _this.mapInstance
        })

        //移动信息
        _this.positionPicker.on('success', function(
          positionResult
        ) {
          console.info(positionResult)
          const { position } = positionResult
          _this.modifyPostion = position
          let pixel = _this.lnglat2container(position)
          let [x, y] = pixel.toString().split(',')
          _this.$nextTick(() => {
            _this.$refs.confirmLat &&
              _this.$refs.confirmLat.setPos(x, y)

            _this.configLatConfig = {
              isSubBtnShow: true
            }

            //显示确定/取消纠偏弹窗
            _this.confirmLatVisible = true
          })
        })

        // 移动失败
        _this.positionPicker.on(
          'fail',
          (positionResult) => {
            console.error(
              'positionPicker().fail: positionResult',
              positionResult
            )

            if (
              positionResult ===
              'USER_DAILY_QUERY_OVER_LIMIT'
            ) {
              _this.$message.warn({
                content:
                  '点位纠偏次数已达最大限制（每日5000次），请次日后再进行该操作',
                key: 10044
              })
            } else {
              _this.configLatConfig = {
                isSubBtnShow: false,
                text:
                  '当前位置非中华人民共和国领土，请重新选择'
              }
            }
          }
        )

        //获取右击信息
        const position = _this.rightClickInfo
          ? [
              _this.rightClickInfo.position[0],
              _this.rightClickInfo.position[1]
            ]
          : []
        _this.positionPicker.start(position)
      })
    },
    // 经纬度坐标转成容器像素坐标
    lnglat2container(lnglat) {
      if (!lnglat) return
      // var lnglat = new AMap.LngLat(pos);
      var pixel = this.mapInstance.lngLatToContainer(lnglat)
      return pixel.round()
    },
    //清除地图所有覆盖物
    clearMapMarker() {
      this.clearMarker()
      //清除当前选中覆盖物
      this.backMark && this.removeMarker(this.backMark)
      //清楚悬浮物
      this.tipMarker && this.tipMarker.hide()
      //关闭悬浮窗体
      this.infoWindow && this.infoWindow.close()
    },
    // eslint-disable-next-line no-unused-vars
    mapSetCenter(pos) {
      // 传入经纬度，设置地图中心点
      let position = new AMap.LngLat(pos[0], pos[1]) // 标准写法
      // 简写 var position = [116, 39];
      this.mapInstance.setCenter(position)
    },
    //取消确认纠偏
    cancelConfirmHandle() {
      this.confirmLatVisible = false
      this.positionPicker.stop()
      refreshAmapMarks(this.type, this.selectNode)
    },
    /**
     * 地图初始化
     */
    mapInit() {
      let that = this
      this.$nextTick(() => {
        //路网
        /*var roadNet = new AMap.TileLayer.RoadNet({
          zIndex: 999
        });*/
        //基础地图
        /*  var baseLayer = new AMap.TileLayer({
          //zooms:[0,15],    //可见级别
          visible: true, //是否可见
          opacity: 1, //透明度
          zIndex: 0, //叠加层级
        }); fixsxy默认路网图*/
        let baseLayer = new AMap.TileLayer({
          zooms: [0, 17], //可见级别
          visible: true, //是否可见
          opacity: 1 //透明度
          // zIndex: this.mapZIndexConfig.baseLayer //叠加层级
        })
        this.mapInstance = new AMap.Map('mapcontainer', {
          cursor: 'default',
          center: [102.709372, 25.046432], //云南
          // center: [106.550464, 29.563761],//重庆
          resizeEnable: true,
          animateEnable: true,
          disableSocket: true,
          autoSetFitView: true,
          //viewMode: '3D',
          showLabel: true, //不显示地图文字标记
          labelzIndex: 130,
          topAdcodes: [that.baseTopAdcodes],
          // pitch: 0,
          zoom: 6,
          //mapStyle: 'amap://styles/1ca5820aeb977f88e1b07925d4ff1d4c',
          mapStyle:
            'amap://styles/71422d8cf6d8e6228fc5283f83817efa', //fix原来
          // mapStyle: 'amap://styles/darkblue', //云南
          layers: [
            // baseLayer,fixsxy
            //layer,
            //googleLayer,
            this.roadNet,
            this.trafficLayer,
            //disCountry
            baseLayer
          ] //图层
        })
        // 地图控件 fixsxy
        /* this.mapInstance.plugin(['AMap.ControlBar'], function() {
          var controlBar = new AMap.ControlBar({
            position: {
              top: '20px',
              right: '20px',
            },
          });
          that.mapInstance.addControl(controlBar);
        }); */
        /*  this.mapInstance.plugin(['AMap.Scale'], function() {
          var scale = new AMap.Scale();
          console.error('haha');
          that.mapInstance.addControl(scale);
        }); */
        //显示鹰眼
        /* this.mapInstance.plugin(['AMap.OverView'], function() {
          let view = new AMap.OverView({
            isOpen: false
          });
          that.mapInstance.addControl(view);
        }); */
        /* let oS = new AMap.Scale();
        this.mapInstance.addControl(oS); */
        //当前聚焦的区域
        this.currentAreaNode = null

        //鼠标hover提示内容
        let ele = document.createElement('div') //  .getElementById('maptip');
        ele.className = 'tipMarker top'
        this.$tipMarkerContent = ele
        this.tipMarker = new AMap.Marker({
          content: this.$tipMarkerContent,
          offset: new AMap.Pixel(0, 0),
          bubble: true
        })

        // 地图加载完成后触发
        that.mapInstance.on('complete', function() {
          that.$emit('amapComplete')
          //that.mapInstance.panBy(-250, -300);
          //地图内容，地图盖板
          that.initMapContent()
          //地图缩放
          that.mapInstance.on('zoomend', function(e) {
            //在移动纠偏坐标时阻止该事件
            if (that.stopEventByConfirm()) {
              return
            }
            that.zoomFlag = true
            that.moveFlag = false
            clearTimeout(that.zoomEndTimer)
            that.zoomEndTimer = setTimeout(() => {
              if (that.zoomFlag && !this.moveFlag) {
                that.mapChanged(e)
                that.zoomFlag = false
              }
            }, 600)

            let zom = that.mapInstance.getZoom(),
              feaps = that.districtExplorer.getAllFeaturePolygons()
            // console.error(feaps);
            if (zom >= 15) {
              //清除已有的绘制内容
              /* that.districtExplorer.clearFeaturePolygons();
              if (this.loadCurArea) {
                that.mapInstance.remove(that.renderPolygon);
              } */
            } else if (
              (!feaps || feaps.length === 0) &&
              !that.loadCurArea
            ) {
              //that.refreshAreaNode(that.currentAreaNode);
              that.renderAreaPolygons(that.currentAreaNode)
            }

            /*  console.info(
              'zoomend  ',
              e,
              zom,
              that.districtExplorer.getAllFeaturePolygons()
            ); */
          })
          // 地图移动
          // eslint-disable-next-line no-unused-vars
          that.mapInstance.on('moveend', function(e) {
            //在移动纠偏坐标时阻止该事件

            // that.mapChanged(e);
            if (that.stopEventByConfirm()) {
              return
            }
            that.zoomFlag = false
            that.moveFlag = true
            clearTimeout(that.zoomEndTimer)
            that.zoomEndTimer = setTimeout(() => {
              if (!that.zoomFlag && that.moveFlag) {
                that.mapChanged(e)
                that.moveFlag = false
              }
            }, 600)
          }) //fixsxy
          //地图大小变化
          that.mapInstance.on('resize', function(e) {
            //在移动纠偏坐标时阻止该事件
            if (that.stopEventByConfirm()) {
              return
            }
            // that.mapChanged(e);
            clearTimeout(that.zoomEndTimer)
            that.zoomEndTimer = setTimeout(() => {
              that.mapChanged(e)
            }, 600)
          })

          setTimeout(() => {
            //加载路线图层
            that.showLineLayer()
          }, 1000)
        })

        //地图单击
        /*that.mapInstance.on('click', function(ev) {
            //event.stopPropagation()// 阻止冒泡
            // 触发事件的对象
            var target = ev.target;

            // 触发事件的地理坐标，AMap.LngLat 类型
            var lnglat = ev.lnglat;

            // 触发事件的像素坐标，AMap.Pixel 类型
            var pixel = ev.pixel;

            // 触发事件类型
            var type = ev.type;

            console.info(target,type);
        });*/
        /*  that.mapInstance.on('click', function(ev) {
          // 触发事件的对象
          // var target = ev.target;

          // 触发事件的地理坐标，AMap.LngLat 类型
          var lnglat = ev.lnglat;
          console.error('target', lnglat);
          that.getAddressByLat([lnglat.lng, lnglat.lat]);
        }); */
        //判断用户修改摄像机信息权限
        this.editCameraInfoSign = true //this.$_btnPermissionCheck(10931010);
        // this.setMapInstance(this.mapInstance);
      })
    },
    //移动纠偏阻止该事件
    stopEventByConfirm() {
      return this.confirmLatVisible
    },
    /**
     * 卫星图层、基础图层来回切换
     */
    checkGoogleMapType(type) {
      if (type === 'google') {
        //this.mapLayerType = type;
        //this.districtExplorer.clearFeaturePolygons();
        this.mapInstance.add(this.googleLayer)
      }
      if (type === 'satellite') {
        this.mapInstance.add(this.satellite)
      }
      if (type === 'roadNet') {
        this.mapInstance.add(this.roadNet)
        this.mapInstance.remove(this.googleLayer)
        this.mapInstance.remove(this.satellite)
        this.mapInstance.remove(this.trafficLayer)
      }
      if (type === 'trafficLayer') {
        // this.mapInstance.add(this.trafficLayer);
        this.trafficLayer.setMap(this.mapInstance)
        this.mapInstance.remove(this.googleLayer)
        this.mapInstance.remove(this.satellite)
        // this.mapInstance.remove(this.roadNet);
      }
      if (type === 'base') {
        //this.mapLayerType = type;

        this.mapInstance.remove(this.googleLayer)
        this.mapInstance.remove(this.satellite)
        // this.mapInstance.remove(this.roadNet);
        this.mapInstance.remove(this.trafficLayer)
      }
      this.mapLayerType = type
      this.renderAreaPolygons(this.currentAreaNode)

      /*var googleLayer = new AMap.TileLayer.Satellite({
          zooms:[15,24],    //可见级别
          getTileUrl: 'http://mt2.google.cn/vt/lyrs=s@817&hl=zh-CN&gl=cn&x=[x]&y=[y]&z=[z]&s=Galil',
          zIndex:20,
          //tileUrl : 'http://www.google.cn/maps/vt?lyrs=s@817&gl=cn&x=[x]&y=[y]&z=[z]',
          opacity : 1
      });*/
    },

    /**
     * 路线图层
     * @returns {boolean}
     */
    showLineLayer() {
      let wms = new AMap.TileLayer.WMS({
        url: 'http://testqxsj.txffp.com/geoserver/sf/wms',
        blend: false,
        tileSize: 512,
        zIndex: 300,
        // zoom: [8, 10],fixsxy添加
        /*params:{"service":"WMS","version":"1.1.0","request":"GetMap","layers":"sf:71118_polyline","styles":"",
            "bbox":"80.4272689819336,20.3882083892822,131.186065673828,49.2997512817383","width":"579","height":"330",
            "srs":"EPSG:4326","format":"application/openlayers","CQL_FILTER":'lx%20%3D%207'}*/
        params: {
          STYLES: '',
          LAYERS: 'sf%3Ahighway_71118',
          FORMAT: 'image%2Fpng',
          SERVICE: 'WMS',
          VERSION: '1.1.1',
          REQUEST: 'GetMap',
          SRS: 'EPSG%3A3857',
          CQL_FILTER: 'lx%20%3D%207',
          BBOX:
            '12653649.606408,2625087.7436574,12697806.169576,2656394.0569978',
          WIDTH: 512,
          HEIGHT: 363
        }
      })
      wms.setMap(this.mapInstance)
    },
    /**
     * 画多条路线
     */
    drawMulLine(lineArr, center) {
      console.log(center, 'center=')
      if (!lineArr.length) {
        return
      }
      if (this.currPolylines.length > 0) {
        this.currPolylines.forEach((item) => {
          this.mapInstance.remove(item)
        })
      }
      for (let i = 0; i < lineArr.length; i++) {
        let polyline = new AMap.Polyline({
          path: lineArr[i],
          strokeColor: 'red',
          strokeOpacity: 1,
          strokeWeight: 6,
          strokeStyle: 'solid',
          strokeDasharray: [10, 5],
          geodesic: true
        })

        this.currPolylines[i] = polyline
        polyline.setMap(this.mapInstance)
      }
      this.mapInstance.setCenter(center)
      this.mapInstance.setZoom(13)
    },
    removeDrawMulMarker() {
      if (this.currPolylines.length > 0) {
        this.currPolylines.forEach((item) => {
          this.mapInstance.remove(item)
        })
      }
    },
    /**
     * 画路线
     */
    drawLine(lineArr, center) {
      let polyline = new AMap.Polyline({
        path: lineArr,
        strokeColor: 'red',
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeStyle: 'solid',
        strokeDasharray: [10, 5],
        geodesic: true
      })
      if (this.currPolyline) {
        this.mapInstance.remove(this.currPolyline)
      }
      this.currPolyline = polyline
      polyline.setMap(this.mapInstance)
      this.mapInstance.setCenter(center)
      this.mapInstance.setZoom(13)
    },
    /**
     * 地图大小、层级等变化
     */
    mapChanged() {
      //console.info('mapChanged',e);
      let that = this
      //0.5秒防抖
      clearTimeout(window.setMapTimeout)
      window.setMapTimeout = setTimeout(function() {
        // console.info('更新摄像头数据');
        //地图层级变动，清除marker的title信息框
        if (that.infoWindow) {
          that.infoWindow.close()
        }
        that.$emit(
          'amapChanged',
          that.currentAreaNode.adcode
        )
      }, 500)
      // this.eventRegionCode = 100000;//默认设置未中国地图code：100000
      // this.$refs.amapIns.switch2AreaNode(this.eventRegionCode);//高德库切换区域赋值
      /*let that = this;
      //增加防抖保护
      that.Utils.debounce(function(){
          console.info('mapChanged',e);
          that.$emit('amap-changed');
      },1500,false);*/
    },

    /**
     * 创建地图显示内容
     */
    initMapContent() {
      let that = this

      that.mapInstance.AmbientLight = new AMap.Lights.AmbientLight(
        [1, 1, 1],
        0.3
      )
      that.mapInstance.DirectionLight = new AMap.Lights.DirectionLight(
        [0, 0, 1],
        [1, 1, 1],
        0.5
      )

      AMapUI.load(
        ['ui/geo/DistrictExplorer', 'lib/$'],
        function(DistrictExplorer) {
          //创建一个实例
          let distExp = new DistrictExplorer({
            eventSupport: true, //打开事件支持
            map: that.mapInstance
          })

          //监听feature的hover事件
          distExp.on(
            'featureMouseout featureMouseover',
            function(e, feature) {
              that.toggleHoverFeature(
                feature,
                e.type === 'featureMouseover',
                e.originalEvent
                  ? e.originalEvent.lnglat
                  : null
              )
            }
          )

          //监听鼠标在feature上滑动
          distExp.on('featureMousemove', function(e) {
            //更新提示位置
            that.tipMarker.setPosition(
              e.originalEvent.lnglat
            )
          })

          //feature被点击
          distExp.on('featureClick', function() {
            //console.info('featureClick',e);
            //that.loadDefaultAmapEvent();
            /*that.removeMarker(that.backMark);
          this.chosedMark.show();*/
            that.$root.$emit('removeEventMarker', '')
          })

          //外部区域被点击
          distExp.on('outsideClick', function() {
            //console.info('outsideClick',e);
            //that.loadDefaultAmapEvent();
            that.$root.$emit('removeEventMarker', '')
          })
          that.districtExplorer = distExp

          //加载指定区块，默认全国
          that.switch2AreaNode(that.baseTopAdcodes)
        }
      )
    },

    /**
     * 根据区块Hover状态设置相关样式
     */
    toggleHoverFeature(feature, isHover, position) {
      this.tipMarker.setMap(
        isHover ? this.mapInstance : null
      )

      if (!feature) {
        return
      }

      let props = feature.properties

      if (isHover) {
        //更新提示内容
        this.$tipMarkerContent.innerHTML = props.name
        //更新位置
        this.tipMarker.setPosition(position || props.center)
      }

      //更新相关多边形的样式
      let polys = this.districtExplorer.findFeaturePolygonsByAdcode(
        props.adcode
      )
      for (let i = 0, len = polys.length; i < len; i++) {
        polys[i].setOptions({
          strokeColor: isHover ? '#63a5fe' : '#0c2254',
          fillColor: isHover ? '#0e397c' : '#0d4692',
          //fillOpacity: isHover ? 0.5 : 1
          zIndex: isHover ? 2 : 1
        })
      }
    },

    /**
     * 根据地址获取经纬度
     * @param {*} lnglat
     */
    getLatByAddress(address) {
      let geocoder = new AMap.Geocoder({
        //city: this.baseTopAdcodes, //城市设为北京，默认：“全国”
        city: this.baseTopAdcodesName
      })
      // console.error('hah', address);
      let marker = new AMap.Marker({
        zIndex: 401
      })
      let that = this
      that.mapInstance.remove(this.currentPosMarker)
      /*  AMap.plugin('AMap.DistrictSearch', function() {
        new AMap.DistrictSearch({
          extensions: 'all',
          subdistrict: 0
        }).search(this.baseTopAdcodesName, function(status, result) {
          // 外多边形坐标数组和内多边形坐标数组
          var outer = [
            new AMap.LngLat(-360, 90, true),
            new AMap.LngLat(-360, -90, true),
            new AMap.LngLat(360, -90, true),
            new AMap.LngLat(360, 90, true)
          ];
          var holes = result.districtList[0].boundaries;
          var pathArray = [outer];
          pathArray.push.apply(pathArray, holes);
          console.error(pathArray); */
      geocoder.getLocation(address, function(
        status,
        result
      ) {
        if (
          status === 'complete' &&
          result.geocodes.length
        ) {
          var lnglat = result.geocodes[0].location
          // document.getElementById('lnglat').value = lnglat;
          that.setLatResult(lnglat + '、' + address)
          marker.setPosition(lnglat)
          that.mapInstance.add(marker)
          that.mapInstance.setFitView(marker)
        } else {
          console.error('根据地址查询位置失败')
        }
      })
      /* });
      }); */
      this.currentPosMarker = marker
    },
    removeCurrentPosMarker() {
      this.mapInstance.remove(this.currentPosMarker)
    },
    /**
     * 根据经纬度获取地址
     * @param {*} areaNode
     */
    getAddressByLat(lnglat) {
      let geocoder = new AMap.Geocoder({
        city: this.baseTopAdcodes, //城市设为北京，默认：“全国”
        radius: 1000 //范围，默认：500
      })
      let that = this
      let marker = new AMap.Marker({
        zIndex: 401
      })
      that.mapInstance.remove(this.currentPosMarker)
      this.mapInstance.add(marker)
      marker.setPosition(lnglat)
      geocoder.getAddress(lnglat, function(status, result) {
        if (status === 'complete' && result.regeocode) {
          var address = result.regeocode.formattedAddress
          // console.error('address', address);
          that.setLatResult(lnglat + '、' + address)
          /* document.getElementById('address').value = address; */
        } else {
          console.error('根据经纬度查询地址失败')
        }
      })
      this.currentPosMarker = marker
    },
    /**
     * 绘制某个区域的边界
     */
    renderAreaPolygons(areaNode) {
      // console.error(222222222222222);
      //
      let _this = this
      //清除已有的绘制内容
      this.districtExplorer.clearFeaturePolygons()
      if (this.loadCurArea) {
        let fillColor = 'rgba(8,34,74,0.75)'
        let fillOpacity = 0.9
        if (this.renderPolygon) {
          // console.error(1111111111111111);
          // this.renderPolygon.hide();
          this.mapInstance.remove(this.renderPolygon)
        }

        new AMap.DistrictSearch({
          extensions: 'all',
          subdistrict: 0
        }).search(_this.baseTopAdcodesName, function(
          status,
          result
        ) {
          // 外多边形坐标数组和内多边形坐标数组
          var outer = [
            new AMap.LngLat(-360, 90, true),
            new AMap.LngLat(-360, -90, true),
            new AMap.LngLat(360, -90, true),
            new AMap.LngLat(360, 90, true)
          ]
          var holes = result?.districtList?.[0].boundaries

          var pathArray = [outer]
          pathArray.push.apply(pathArray, holes)
          _this.renderPolygon = new AMap.Polygon({
            pathL: pathArray,
            //线条颜色，使用16进制颜色代码赋值。默认值为#006600
            strokeColor: 'orange',
            strokeWeight: 4,
            //轮廓线透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
            strokeOpacity: 0.5,
            //多边形填充颜色，使用16进制颜色代码赋值，如：#FFAA00
            fillColor: fillColor,
            //多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
            fillOpacity: fillOpacity,
            //轮廓线样式，实线:solid，虚线:dashed
            strokeStyle: 'solid',
            /*勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效， 此属性在    
                ie9+浏览器有效 取值： 
                实线：[0,0,0] 
                虚线：[10,10] ，[10,10] 表示10个像素的实线和10个像素的空白（如此反复）组成的虚线
                点画线：[10,2,10]， [10,2,10] 表示10个像素的实线和2个像素的空白 + 10个像素的实 
                线和10个像素的空白 （如此反复）组成的虚线*/
            strokeDasharray: [10, 2, 10]
          })
          _this.renderPolygon.setPath(pathArray)
          _this.mapInstance.add(_this.renderPolygon)
        })
      } else {
        let fillColor =
            this.mapLayerType === 'google' ? 0 : '#0d4692',
          // this.mapInstance.add(this.googleLayer);
          // let fillColor = this.mapLayerType === 'google' ? 0 : this.googleLayer,
          strokeColor = 'orange', // '#0c3574',
          fillOpacity =
            this.mapLayerType === 'google' ? 0 : 0

        if (areaNode.adcode !== 100000) {
          //绘制子区域
          this.districtExplorer.renderSubFeatures(
            areaNode,
            function() {
              return {
                cursor: 'default',
                bubble: true,
                strokeColor: strokeColor, //线颜色
                strokeOpacity: 1, //线透明度
                strokeWeight: 2, //线宽
                fillColor: fillColor, //填充色
                fillOpacity: fillOpacity, //填充透明度
                zIndex: 1
              }
            }
          )
        }

        //绘制父区域
        this.districtExplorer.renderParentFeature(
          areaNode,
          {
            cursor: 'default',
            bubble: true,
            strokeColor: strokeColor, //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 0, //线宽
            fillColor: fillColor, //填充色
            fillOpacity: 0.4, //填充透明度
            zIndex: 1
          }
        )
      }
      if (window.innerWidth >= 1920) {
        if (this.$g.regionCode === 500000) {
          this.mapInstance.setZoom(8)
          this.setDefaultZoom(8)
        } else {
          this.mapInstance.setZoom(7)
          this.setDefaultZoom(7)
        }
      } else {
        if (this.$g.regionCode === 500000) {
          this.mapInstance.setZoom(7)
          this.setDefaultZoom(7)
        } else {
          this.mapInstance.setZoom(6)
          this.setDefaultZoom(6)
        }
      }
    },

    /**
     * 加载区域
     */
    loadAreaNode(adcode, callback) {
      let _this = this

      this.districtExplorer.loadAreaNode(adcode, function(
        error,
        areaNode
      ) {
        //console.info('areaNode  ',adcode,areaNode);
        _this.districtExplorer.clearFeaturePolygons()
        if (error) {
          if (callback) {
            callback(error)
          }
          //console.error('loadAreaNode error ', error);
          return
        }

        if (callback) {
          callback(null, areaNode)
        }
      })

      return false
    },

    /**
     * 切换区域后刷新显示内容
     */
    refreshAreaNode(areaNode) {
      this.districtExplorer.setHoverFeature(null)

      //更新地图视野
      this.mapInstance.setBounds(areaNode.getBounds())
      this.renderAreaPolygons(areaNode)
    },

    /**
     * 切换区域
     */
    switch2AreaNode(adcode, callback) {
      //let currentAreaNode = null;
      if (
        this.currentAreaNode &&
        '' + this.currentAreaNode.getAdcode() ===
          '' + adcode
      ) {
        return
      }

      let that = this
      this.loadAreaNode(adcode, function(error, areaNode) {
        if (error) {
          if (callback) {
            callback(error)
          }
          return
        }
        that.currentAreaNode = areaNode //this.currentAreaNodeBack =
        //设置当前使用的定位用节点
        that.districtExplorer.setAreaNodesForLocating([
          that.currentAreaNode
        ])
        that.refreshAreaNode(areaNode)
        if (callback) {
          callback(null, areaNode)
        }
      })
    },

    /**
     * 鼠标hover，显示对应区块
     *
     * @param adcode
     */
    districtHover(adcode) {
      this.districtExplorer.setHoverFeature(
        this.currentAreaNode.getSubFeatureByAdcode(adcode)
      )
    },

    /**
     * 关闭播放器
     */
    cameraInfoWindowClose() {
      let that = this

      /*console.log("cameraInfoWindowClose");
      // 关闭信息窗体
      if(that.cameraInfoWindow) that.cameraInfoWindow.close();
      that.cameraInfoWindow = null;*/
      that.cameraMarkVisable = false
      that.currentPlayCamera = {}
    },

    /**
     * 打开播放器
     * @param record
     */
    cameraInfoWindowShow(record, pixel, isFromCollect) {
      console.log('record', record)
      let that = this
      that.$nextTick(() => {
        that.$refs.amapPlayer.getFaultDetails(record.data.cameraId)
        that.$refs.amapPlayer.timeData = null
      })
      
      if (record.data.cameraId) {
        this.videoCameraId = record.data.cameraId
        this.videoCameraNum = record.data.cameraNum
      }
      if (record && record.data) {
        //that.currentPlayCamera = record.data;
        // console.info('cameraInfoWindowShow ', record.data, pixel);

        // 传入经纬度，设置地图中心点
        /*  let position = new AMap.LngLat(
          record.data.position[0],
          record.data.position[1]
        );fixsxy */ // 标准写法

        // this.mapInstance.setCenter(position);fix

        //设置显示层级，判断当前点位是否在范围内，否则需要调整层级
        /*let currZoom = this.mapInstance.getZoom(), //当前层级
          newZoom = 18,
          myZoom = parseInt(record.data.zoomlevel) || 0;
        //层级大于当前层级，调整到指定层级
        if (myZoom) {
          newZoom = myZoom; // 存在特定层级
          if (myZoom <= currZoom) {
            newZoom = currZoom; // 当前层级已经显示摄像头，不做调整
          }
        }
        this.mapInstance.setZoom(newZoom);fixsxy：删除变更图层大小*/

        //marker.setIcon('//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png')

        //this.mapInstance.setZoomAndCenter(18, [record.data.position[0], record.data.position[1]]);

        that.cameraMarkVisable = true
        // that.$refs.amapPlayer.resolutionValue = record.data.resolutionValue;
        //鼠标位置
        const f = 1 > 2
        if (pixel && pixel.x && pixel.y && f) {
          this.$nextTick(() => {
            // console.log('pixel',pixel,that.$refs)
            that.$refs.amapPlayer.setWindowSite2(
              pixel.x,
              pixel.y
            )
          })
        }
        that.$nextTick(() => {
          //setTimeout(() => {
          //高清停流

          /*  if(tempResolution === 1 && tempCamaraInfo){
            this.stopStream(tempCamaraInfo);
          } */
          // this.$refs.amapPlayer.setResolution(
          //   record.data.resolutionValue
          // )
          //高清禁止切换
          // this.$refs.amapPlayer.setNoToggleResolution(record.data.resolutionValue);fixsxyresolutionValue2
          this.$refs.amapPlayer.setNoToggleResolution(
            record.data.resolutionValue2
          )
          that.$refs.amapPlayer.getVideoUrlToPlay(
            record.data,
            'map'
          )
          /* tempResolution = this.$refs.amapPlayer.getResolution();
          tempCamaraInfo = record.data; */
          //}, 1000);
        })
      }
      if (record && isFromCollect) {
        that.cameraMarkVisable = true
        that.$nextTick(() => {
          //setTimeout(() => {
          //收藏列表无法给经纬度，故鼠标位置随便写的
          that.$refs.amapPlayer.setWindowSite(663, 207)
          that.$refs.amapPlayer.getVideoUrlToPlay(record)
          //}, 1000);
        })
      }
    },
    // 播放器关闭
    closeVideoDialog(el) {
      console.log('kjsdlkfj', el)
      const dom = el.querySelector('.video-dialog-group')
      this.$nextTick(() => {
        dom &&
          (dom.style.left = '52%') &&
          (dom.style.top = '20%')
      })
    },
    /**
     * 动态获取摄像机点位图标，类型、状态
     */
    getMarkerIcon(type, status) {
      let that = this,
        iconBase = {}
      if (!status || status === '0') {
        iconBase = that.cameraOfflineImgUrl
      }
      if (status === '1') {
        iconBase = that.cameraImgUrl
        // iconBase = that.cameraGifUrl;
      }
      if (status === '2') {
        iconBase = that.cameraErrorImgUrl
      }

      return new AMap.Icon({
        image: iconBase[type] || iconBase['1'],
        size: new AMap.Size(
          that.cameraIconWidth,
          that.cameraIconHeight
        ), // 图标尺寸
        //imageOffset : new AMap.Pixel(0-that.cameraIconWidth/2, 0-that.cameraIconHeight),
        imageSize: new AMap.Size(
          that.cameraIconWidth,
          that.cameraIconHeight
        ) // 根据所设置的大小拉伸或压缩图片
        //imageOffset : new AMap.Pixel(0-that.cameraIconWidth/2, 0-that.cameraIconHeight),
        //anchor:'bottom-center', // 设置锚点方位
      })
    },

    /**
     * 清空覆盖物
     * @param {*} list
     */
    clearMarker() {
      this.currentMarkers &&
        this.mapInstance.remove(this.currentMarkers)
    },
    /**
     * 批量加载点数据创建摄像机marker
     * @param list
     */
    pointsSetIn(list) {
      let that = this,
        markerList = []
      /*  if (this.cluster) {
        this.cluster.setMap(null);
      } */
      if (!this.infoWindow) {
        this.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
          zoom: 8
        })
      }

      // that.mapInstance.clearMap();
      that.mapInstance.remove(that.currentMarkers) //fixsxy
      /*  if (that.currentMarkers.length > 0) {
        that.currentMarkers.removeOverlay(this.markerListTemp);
      }
      console.log('hah', that.currentMarkers); */
      if (list.length) {
        let num = list.length

        // 摄像机图标mouseover事件
        let markerhover = (e) => {
          that.infoWindow.setContent(e.target.content)
          that.infoWindow.open(
            that.mapInstance,
            e.target.getPosition()
          )

          that.tipMarker.hide()
          //console.info('that.tipMarker.hide();');
        }

        // 摄像机图标mouseout事件
        let markermouseout = () => {
          // console.log("infoWindow=>触发离开事件");
          that.infoWindow.close()
          that.tipMarker.show()
        }
        //console.info('markerSetInit  ',groupStyleMap['0']);

        //如果云南，位置纠偏

        // 加载点数据
        for (let i = 0, len = num; i < len; i++) {
          let position = []
          let isRestore = false
          if (that.$g.regionCode == 530000) {
            if (
              list[i].correctLongitude &&
              parseInt(list[i].correctLongitude) !== 0 &&
              parseInt(list[i].correctLatitude) &&
              parseInt(list[i].correctLatitude) !== 0
            ) {
              position = [
                list[i].correctLongitude,
                list[i].correctLatitude
              ]
              isRestore = true
            } else {
              position = [
                list[i].longitude,
                list[i].latitude
              ]
              isRestore = false
            }
          }
          let marker = new AMap.Marker({
            position: position, //new AMap.LngLat(list[i].longitude,list[i].latitude),
            zIndex: 301,
            //offset: new AMap.Pixel(0 - that.cameraIconWidth/2, 0 -  that.cameraIconHeight),
            offset: new AMap.Pixel(
              0 - that.cameraIconWidth / 2,
              0 - that.cameraIconHeight
            ),
            //icon: '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
            // title: list[i].roadId || list[i].road + ' '+list[i].cameraNum,
            //topWhenClick:true, // 点击置顶
            //draggable:true, // 是否可以拖拽
            //raiseOnDrag:true,// 是否开启点标记离开地图的效果
            //animation:'AMAP_ANIMATION_DROP', // 点掉落效果
            clickable: true,
            icon: /* fixsxy ['zsy_qjsxj_00001','zsy_qjsxj_00002','zsy_qjsxj_00003','zsy_qjsxj_hall_01','zsy_qjsxj_hall_02'].indexOf(list[i].cameraNum)!=-1?that.cameraPanoramaImgUrl: */ that.getMarkerIcon(
              // list[i].classify,fixsxy
              String(list[i].organizationClassify),
              list[i].onlineStatus
            ), //  groupStyleMap[list[i].onlineStatus === null ? '0' : list[i].onlineStatus],

            extData: {
              onlineStatus:
                [
                  'zsy_qjsxj_hall_01',
                  'zsy_qjsxj_hall_02'
                ].indexOf(list[i].cameraNum) != -1
                  ? '1'
                  : list[i].onlineStatus === null
                  ? 0
                  : list[i].onlineStatus,
              //是否支持一键复位
              isRestore,
              cameraName: list[i].cameraName,
              cameraId: list[i].cameraId,
              cameraNum: list[i].cameraNum,
              organizationId: list[i].organizationId,
              cameraKmpile: list[i].cameraKmpile,
              cameraHmpile: list[i].cameraHmpile,
              roadId: list[i].roadId || list[i].road, // roadId
              regionCode: list[i].regionCode,
              position,
              cameraType: list[i].cameraType,
              kmPile: list[i].kmPile,
              hmPile: list[i].hmPile,
              classify: list[i].classify,
              zoomlevel: list[i].zoomlevel,
              source: list[i].source,
              collectFlag: list[i].favoriteFlag,
              khPile: list[i].khPile,
              info: list[i],
              /* resolutionValue: list[i].organizationId.startsWith('9901004075')
                ? 1
                : 0 fixsxy*/
              resolutionValue:
                list[i].transcodingId ===
                '1354354698693513216'
                  ? 1
                  : 0,
              resolutionValue2:
                list[i].transcodingId ===
                '1354354698693513216'
                  ? 1
                  : 0
            }
          })
          /*  marker.setLabel({
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: '<div style="color:red">haha</div>', //设置文本标注内容
            direction: 'top', //设置文本标注方位
            zIndex: 40
          }); */

          if (
            [
              'zsy_qjsxj_hall_01',
              'zsy_qjsxj_hall_02'
            ].indexOf(list[i].cameraNum) != -1
          ) {
            /*  marker.content = 'zsy_qjsxj_hall_01'== list[i].cameraNum ?'重庆国际博览中心N2号馆（高清）':'重庆国际博览中心N2号馆（超清）'; fixsxy*/
          } else {
            let sta = [
              '<span style="color:#b1bfdc">(离线)</span>',
              '',
              '<span style="color:#fe3708">(异常)</span>'
            ][list[i].onlineStatus]
            /* marker.content = `K${
              list[i].cameraKmpile ? list[i].cameraKmpile : ''
            }${list[i].cameraHmpile ? '+' + list[i].cameraHmpile : ''}${
              typeof sta === 'undefined'
                ? ' (未知)' + list[i].onlineStatus
                : sta
            }`; */
            marker.content = `${list[i]['cameraName']}${
              typeof sta === 'undefined'
                ? ' (未知)' + list[i].onlineStatus
                : sta
            }`
          }

          // 摄像机图标点击事件
          marker.on('click', function(a) {
            //子组件右击编辑窗口开启状态下，点击播放视频失效
            if (
              that.$refs.amapPlayer
                ?.cameraInfoAlongEditVisable
            ) {
              that.$message({
                message: '请关闭修改窗口',
                type: 'warning'
              })
              return
            }

            //that.setSelectCameraIndex(0);

            let cameraInfo = a.target.getExtData()
            //点击的摄像机为地图中心
            that.mapInstance.setCenter(cameraInfo.position)
            that.$root.$emit(
              'updateCollectType',
              cameraInfo.collectFlag ? false : true
            )

            // a.hide();
            //加载当前播放选中图标
            // that.addEventMarker(a, true);
            // that.setSelectCameraIndex(record.index);
            // console.log("pointSimplifierIns click ", e, e.type, record);

            //播放器取消离线不可播放
            /*  if (cameraInfo.onlineStatus === '1') { */
            that.addEventMarker(a, true)
            // console.error('cameraInfo----', cameraInfo);
            that.cameraInfoWindowShow(
              { data: cameraInfo },
              a.pixel || null
            )
            /*   } else {
              that.$message.warning('此摄像机处于离线状态！无法播放视频！');
            }*/
          })

          // 摄像机图标右击事件
          marker.on('rightclick', function(a) {
            console.info(
              'marker mousedown ',
              a.target.getExtData()
            )
            //给右击悬浮物赋值数据
            that.rightClickInfo = a.target.getExtData()
            //判断用户是否有修改摄像机权限
            if (!that.editCameraInfoSign) {
              return
            }
            if (that.cameraMarkVisable) {
              return false
            }
            if (that.chosedMark) {
              that.chosedMark.show()
            }
            // console.error(a.pixel);

            that.$refs.dragMarkerGhost.$el.style.top =
              a.pixel.y + 40 + 'px'
            that.$refs.dragMarkerGhost.$el.style.left =
              a.pixel.x + 396 - 50 + 'px'

            that.addEventMarker(a, true)

            // 显示位置纠偏弹窗
            // 权限判断1092101113
            if (!that.hasPermission(1092101113)) {
              return
            }
            that.showLatVisible = true
            that.$nextTick(() => {
              let data = a.target.getExtData()
              let { x, y } = that.lnglat2containerPixel(
                data.position
              )
              that.$refs['modifyLat'] &&
                that.$refs['modifyLat'].setPos(x, y)
            })
          })

          /*// 摄像机图标右击事件
          marker.on("mousedown", function(a) {
            console.info("marker mousedown ", a.target.getExtData());

            //判断用户是否有修改摄像机权限
            if (!that.editCameraInfoSign) {
              return;
            }
            if(that.cameraMarkVisable){
              return false;
            }
            if(that.chosedMark){
              that.chosedMark.show();
            }

            that.addEventMarker(a,true);
          });*/

          // 摄像机图标mouseover事件
          marker.on('mouseover', markerhover)

          // 摄像机图标mouseout事件
          marker.on('mouseout', markermouseout)
          // marker.emit('mouseover', {target: marker});
          //marker.emit('click', {target: marker});

          markerList.push(marker)
        }

        // 创建覆盖物群组，并将 marker 传给 OverlayGroup
        that.currentMarkers = new AMap.OverlayGroup(
          markerList
        )
        /* this.cluster = new AMap.MarkerClusterer(this.mapInstance, markerList, {
          gridSize: 80,
          maxZoom: 17
        }); */
        // that.currentMarkers = markerList;

        that.mapInstance.add(that.currentMarkers)

        /*// 添加覆盖物群组
        function addOverlayGroup() {

            map.add(overlayGroups);
        }

        // 移除覆盖物群组
        function removeOverlayGroup() {

            map.remove(overlayGroups);
        }*/
      }

      // 移除已创建的 marker
      //this.mapInstance.remove(marker);
    },
    // 经纬度坐标转成容器像素坐标
    lnglat2containerPixel(pos) {
      if (!pos) return
      let lnglat = new AMap.LngLat(pos[0], pos[1])
      let pixel = this.mapInstance.lngLatToContainer(lnglat)
      return pixel.round()
    },
    /**
     * marker标记点击效果
     * @param data
     * @param flag      true    播放状态添加
     *                  false   右击修改状态添加
     */
    addEventMarker(obj, flag = false) {
      let that = this,
        data = obj.target.getExtData()
      if (!data) {
        return false
      }
      //去除树状调阅的marker
      if (this.currentVideoMarker) {
        this.mapInstance.remove(this.currentVideoMarker)
      }
      //当前选中的摄像头
      that.chosedMark = obj.target
      that.chosedMark.hide()
      that.chosedMarkData = data
      // console.error('ppppppppppppp', this.chosedMarkData);
      //移除marker图标
      this.removeMarker(this.backMark)

      let ctype =
        data.classify === '0' || data.classify === '9'
          ? '1'
          : data.classify
      ctype = data.classify === '4' ? '5' : ctype

      let isPanorama =
        [
          'zsy_qjsxj_00001',
          'zsy_qjsxj_00002',
          'zsy_qjsxj_00003',
          'zsy_qjsxj_hall_01',
          'zsy_qjsxj_hall_02'
        ].indexOf(data.cameraNum) != -1

      //加载图标属性
      var eventCameraMarker = new AMap.Marker({
        position: data.position,
        map: this.mapInstance,
        cursor: 'move',
        draggable: false, //that.editCameraInfoSign, // 是否可以拖拽
        zIndex: 1000,
        icon: new AMap.Icon({
          // 状态图片

          image: isPanorama
            ? this.cameraPanoramaGifUrl
            : this.cameraGifUrl[ctype],
          size: isPanorama
            ? new AMap.Size(27, 42)
            : new AMap.Size(
                this.cameraIconWidth2,
                this.cameraIconHeight2
              ), // 图标尺寸
          //imageOffset : new AMap.Pixel(0-this.cameraIconWidth/2, 0-this.cameraIconHeight),
          imageSize: isPanorama
            ? new AMap.Size(27, 42)
            : new AMap.Size(
                this.cameraIconWidth2,
                this.cameraIconHeight2
              ) // 根据所设置的大小拉伸或压缩图片
        }),
        offset: isPanorama
          ? new AMap.Pixel(
              0 - that.cameraIconWidth2 / 2,
              -45
            )
          : new AMap.Pixel(
              0 - that.cameraIconWidth2 / 2,
              0 - that.cameraIconHeight2
            )
        //content:that.$refs.dragMarkerGhost.$el
        // offset : new AMap.Pixel(-10, -10),
      })
      let sta = [
        '<span style="color:#b1bfdc">(离线)</span>',
        '<span class="text-lightblue">(在线)</span>',
        '<span style="color:#aa2507">(异常)</span>'
      ][Number(data.onlineStatus)]
      eventCameraMarker.content = `${
        data.cameraName ? data.cameraName : ''
      }${sta || ''}`

      eventCameraMarker.on('mouseover', (e) => {
        that.infoWindow.setContent(e.target.content)
        that.infoWindow.open(
          that.mapInstance,
          e.target.getPosition()
        )
        that.tipMarker.hide()
      })
      eventCameraMarker.on('mouseout', () => {
        that.infoWindow.close()
        that.tipMarker.show()
      })

      eventCameraMarker.on('mousedown', (e) => {
        //console.info('mousedown ',a,b,that.dragMarkerGhostStyle);
        eventCameraMarker.hide()
        //console.info(that.$refs.dragMarkerGhost);
        //that.dragMarkerGhostStyle = { display:"inline-block", top:e.pixel.y+80-15 + "px", left:e.pixel.x+406-18  + "px"};
        that.$refs.dragMarkerGhost.$el.style.top =
          e.pixel.y + 80 - 15 + 'px'
        that.$refs.dragMarkerGhost.$el.style.left =
          e.pixel.x + 406 - 18 + 'px'

        /* document.onmousemove = function(ev) {
          /！*注册document的mousemove事件*！/
          let pos = ev || window.event;
          //console.info(oEvent);
          //let pos = that.getPos(oEvent);
          that.dragMarkerGhostStyle = {
            display: 'inline-block',
            top: pos.y - 15 + 'px',
            left: pos.x - 18 + 'px',
          };
          that.$refs.dragMarkerGhost.ondragstart = function() {
            /！*鼠标放开清除事件*！/
            console.info('savsdvsdv ');
          };
        }; */
        document.onmouseup = function() {
          /*鼠标放开清除事件*/
          that.dragMarkerGhostStyle = {}
          document.onmousemove = null
          document.onmouseup = null
          that.$root.$emit('removeEventMarker', '')
        }
      })

      if (that.editCameraInfoSign) {
        // marker点拖动结束事件
        eventCameraMarker.on('dragend', function() {
          //console.log("dragend=>", a);
          eventCameraMarker.setPosition(data.position)
          /*var wgs84 = that.$gcoord.transform(
            [a.lnglat.Q, a.lnglat.P],
            that.$gcoord.GCJ02,
            that.$gcoord.WGS84
          );
          console.log("wgs84=>", wgs84);*/

          /*that.$root.$emit("updateCameraLnglatForm", {
            position: { lon: wgs84[0], lat: wgs84[1] - 0.00008 },
            flag: flag
          });*/
        })
      }

      this.mapInstance.add(eventCameraMarker)
      this.backMark = eventCameraMarker

      if (flag) {
        return
      }
      //调用AmapPlayerDialog子组件方法，展示修改弹窗
      //this.$root.$emit("setCameraInfoAlongEditVisable", true);
      //this.$root.$emit("setCameraInfoAlongForm", data);
    },

    /**
     * 移除marker标记
     * @param flag  marker数据
     */
    removeMarker(flag) {
      if (!flag) return
      this.mapInstance.remove(flag)
    },

    loadDefaultAmapEvent() {
      //console.info('loadDefaultAmapEvent ',this.backMark);
      this.$root.$off('removeEventMarker')
      this.$root.$off('editEventMarkerLonlat')
      this.$root.$off('mapPan')

      //移除地图正在处理的marker标记
      this.$root.$on('removeEventMarker', () => {
        if (this.currentVideoMarker) {
          this.removeMarker(this.currentVideoMarker)
        }
        if (this.currentPointVideoMarker) {
          this.mapInstance.remove(
            this.currentPointVideoMarker
          )
        }
        console.log('tata', this.currentPointVideoMarker)
        if (!this.chosedMark) {
          return false
        }
        if (this.cameraMarkVisable) {
          this.backMark.show()
          return false
        }
        //console.info(this.chosedMark);
        this.chosedMark.show()
        this.removeMarker(this.backMark)
        //新增
        if (this.showLatVisible) {
          this.showLatVisible = false
        }
        /*if (this.currentVideoMarker) {
          this.removeMarker(this.currentVideoMarker);
        } */

        if (this.$refs.dragMarkerGhost) {
          this.$refs.dragMarkerGhost.$el.style.top = '-50px'
          this.$refs.dragMarkerGhost.$el.style.left =
            '-50px'
        }
      })

      //设置marker点位置
      this.$root.$on('editEventMarkerLonlat', (nv) => {
        this.backMark.setPosition(nv)
      })

      // eslint-disable-next-line no-unused-vars
      this.$root.$on('mapPan', (nv) => {
        this.mapInstance.panBy(0, 5)
      })
    },

    dragMarkerGhostDragstart() {
      // console.error('dragMarkerGhostDragstart', e);
    },
    dragMarkerGhostDragend() {
      // console.e('dragMarkerGhostDragend', e);
      //this.$root.$emit("removeEventMarker", "");
    },
    dragHandle(obj) {
      this.setDragMove(obj)
    },

    addVideoMarker(dataRef) {
      //隐藏地图播放的图标
      if (this.backMark) {
        this.removeMarker(this.backMark)
      }
      if (this.currentVideoMarker) {
        this.mapInstance.remove(this.currentVideoMarker)
      }
      let that = this
      let ctype =
        dataRef.classify === '0' || dataRef.classify === '9'
          ? '1'
          : dataRef.classify
          ? dataRef.classify
          : '1'
      ctype = dataRef.classify === '4' ? '5' : ctype
      let isPanorama =
        [
          'zsy_qjsxj_00001',
          'zsy_qjsxj_00002',
          'zsy_qjsxj_00003',
          'zsy_qjsxj_hall_01',
          'zsy_qjsxj_hall_02'
        ].indexOf(dataRef.cameraNum) != -1
      let marker = new AMap.Marker({
        position: [dataRef.longitude, dataRef.latitude], //new AMap.LngLat(list[i].longitude,list[i].latitude),
        zIndex: 306,
        //offset: new AMap.Pixel(0 - that.cameraIconWidth/2, 0 -  that.cameraIconHeight),
        offset: new AMap.Pixel(
          0 - that.cameraIconWidth2 / 2,
          0 - that.cameraIconHeight2
        ),
        icon: new AMap.Icon({
          // 状态图片

          image: isPanorama
            ? this.cameraPanoramaGifUrl
            : this.cameraGifUrl[ctype],
          size: isPanorama
            ? new AMap.Size(27, 42)
            : new AMap.Size(
                this.cameraIconWidth2,
                this.cameraIconHeight2
              ), // 图标尺寸
          //imageOffset : new AMap.Pixel(0-this.cameraIconWidth/2, 0-this.cameraIconHeight),
          imageSize: isPanorama
            ? new AMap.Size(27, 42)
            : new AMap.Size(
                this.cameraIconWidth2,
                this.cameraIconHeight2
              ) // 根据所设置的大小拉伸或压缩图片
        })
      })
      this.mapInstance.add(marker)
      this.currentVideoMarker = marker
    },
    addPoint(dataRef) {
      if (this.currentPointMarker) {
        this.mapInstance.remove(this.currentPointMarker)
      }

      let marker = new AMap.Marker({
        position: [dataRef.longitude, dataRef.latitude], //new AMap.LngLat(list[i].longitude,list[i].latitude),
        zIndex: 306,
        //offset: new AMap.Pixel(0 - that.cameraIconWidth/2, 0 -  that.cameraIconHeight),
        offset: new AMap.Pixel(9, -30),
        icon: new AMap.Icon({
          // 状态图片

          image: this.mark_point,
          size: new AMap.Size(18, 30), // 图标尺寸
          //imageOffset : new AMap.Pixel(0-this.cameraIconWidth/2, 0-this.cameraIconHeight),
          imageSize: new AMap.Size(18, 30) // 根据所设置的大小拉伸或压缩图片
        })
      })
      this.mapInstance.add(marker)
      this.currentPointMarker = marker
    },
    //删除点位
    removePointAndPlay() {
      if (this.currentPointVideoMarker) {
        this.mapInstance.remove(
          this.currentPointVideoMarker
        )
      }
    },
    //添加点位并播放
    addPointAndPlay(dataRef) {
      if (this.currentPointVideoMarker) {
        this.mapInstance.remove(
          this.currentPointVideoMarker
        )
      }
      let marker = new AMap.Marker({
        position: [dataRef.longitude, dataRef.latitude], //new AMap.LngLat(list[i].longitude,list[i].latitude),
        zIndex: 900,
        //offset: new AMap.Pixel(0 - that.cameraIconWidth/2, 0 -  that.cameraIconHeight),
        offset: new AMap.Pixel(-25, -25),
        icon: new AMap.Icon({
          // 状态图片

          image: this.mark_video_point,
          size: new AMap.Size(50, 50), // 图标尺寸
          // imageOffset : new AMap.Pixel(9, 15),
          imageSize: new AMap.Size(50, 50) // 根据所设置的大小拉伸或压缩图片
        })
      })
      this.mapInstance.add(marker)
      this.mapInstance.setZoom(
        dataRef?.zoomLevel >= 8 ? dataRef?.zoomLevel : 8
      )
      this.mapInstance.setCenter([
        dataRef.longitude,
        dataRef.latitude
      ])
      this.playTimer && clearTimeout(this.playTimer)
      this.playTimer = setTimeout(() => {
        this.cameraInfoWindowShow(
          { data: dataRef },
          {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
          }
        )
      }, 3000)
      this.currentPointVideoMarker = marker
    },
    removeDrawMarker() {
      if (this.currPolyline) {
        this.mapInstance.remove(this.currPolyline)
      }
    },
    destroyMap() {
      this.mapInstance && this.mapInstance.destroy()
    }
  },
  destroyed() {
    this.zoomEndTimer && clearTimeout(this.zoomEndTimer)
    this.playTimer && clearTimeout(this.playTimer)
  }
}
