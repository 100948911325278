<template>
  <div class="full-width flex  flex-wrap cameral-detail text-white">
    <div v-for="item in getCameralInfo" :key="item.id" class="flex c-item">
      <span class="c-tit">{{ item.name }}:</span>
      <span class="ellipsis flex-one">{{ item.info }}</span>
    </div>
  </div>
</template>

<script>
import { CLASSIFY/* , DIRECTOIN, CAMERATYPE */ } from '@/tools';
export default {
  name: 'CameralDetail',
  props: {
    cameraInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      list: [
        {
          id: '1',
          name: '地区',
          // type: 'monitorRegionName',
          type: 'regionId',
        },
        {
          id: '2',
          name: '管辖单位',
          type: 'organizationName',
        },
        {
          id: '3',
          name: '摄像机名称',
          type: 'cameraName',
        },
        {
          id: '4',
          name: '路线',
          // type: 'roadName',
          type: 'roadCode',
        },
        {
          id: '5',
          name: '经纬度',
          type: 'longAndLati',
        },
        {
          id: '6',
          name: '桩号',
          type: 'kmPile',
        },
        {
          id: '7',
          name: '所属类别',
          type: 'classifyCode',
        },
        {
          id: '8',
          name: '方向',
          type: 'derectionCode',
        },
        {
          id: '9',
          name: '摄像机类型',
          type: 'cameraType',
        },
        {
          id: '10',
          name: '接入方式',
          // type: 'transcodingName',
          type: 'reportSource',
        },
        {
          id: '11',
          name: '接入方',
          type: 'transcodingName',
        },
      ],
    };
  },
  computed: {
    getCameralInfo() {
      /*  if(item.type === 'classifyCode'? CLASSIFY[cameraInfo[item.type]]:item.type==='derectionCode'?DIRECTOIN[cameraInfo[item.type]]
        cameraInfo[item.type] */
      let reportText = {
        '2': '平台上报',
        '1': '网关上报',
      };
      let list = this.list.map((item) => {
        /*   if (item.type === 'classifyCode') {
          this.$set(item, 'info', CLASSIFY[this.cameraInfo[item.type]]);
        } else if (item.type === 'derectionCode') {
          this.$set(item, 'info', DIRECTOIN[this.cameraInfo[item.type]]);
        } else if (item.type === 'cameraType') {
          this.$set(item, 'info', CAMERATYPE[this.cameraInfo[item.type]]);
        } else {
          this.$set(item, 'info', this.cameraInfo[item.type]);
        } */
        if (item.type === 'reportSource') {
          this.$set(item, 'info', reportText[this.cameraInfo[item.type]]);
        } else if (item.type === 'classifyCode') {
          this.$set(item, 'info', CLASSIFY[this.cameraInfo[item.type]]);
        } else if(item.type === 'longAndLati') {
          this.$set(item, 'info', this.cameraInfo['correctLongAndLati'] ? this.cameraInfo['correctLongAndLati'] : this.cameraInfo['longAndLati']);
        }else {
          this.$set(item, 'info', this.cameraInfo[item.type]);
        }
        return item;
      });
      return list;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.cameral-detail {
  background: rgba(0, 0, 0, 0.4);
}
.c-item {
  width: 50%;
  padding: 0 5px;
}
.c-tit {
  width: 80px;
}
</style>
