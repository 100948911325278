<template>
  <div class="modify-modal" :style="styleObj" v-show="visible">
      <span class="close absolute text-white pointer" @click="closeHandle">x</span>
      <div class="modify-modal-content">
          <div class="modify-modal-info flex items-center text-white">
            <a-icon type="exclamation-circle" class="m-r-xs"/>
            摄像机点位偏移，我来纠正！
          </div>
          <div class="modify-modal-footer m-t-sm flex items-center justify-center">
              <template>
              <span class="btn-m btn-restore m-r-sm pointer" @click="restoreHandle" v-show="isRestore">一键复位</span>
              <span></span>
              </template>
              <span class="btn-m btn-modify pointer" @click="posHandle">位置纠偏</span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'ModifyLat',
    props:{
        visible:{
            type:Boolean,
            default:false,
        },
        cameraInfo:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return {
            left:0,
            top:0
        }
    },
    computed: {
        isRestore(){
            return this.cameraInfo.isRestore || false
        },
        styleObj(){
            return {
                left: `${this.left - 125}px`,
                top: `${this.top - 145}px`,
                opacity:1,
            }
        }
    },
    methods:{
        //关闭弹窗
        closeHandle() {
            this.$emit('update:visible',false);
        },
        //一键复位
        restoreHandle() {
            this.$emit('restoreOldPostion')
        },
        //位置纠偏
        posHandle() {
            this.$emit('restorePos')
        },
        //设置位置
        setPos(x,y) {
            this.left = x;
            this.top = y;
        }
    }
}
</script>

<style lang="less" scoped>
.modify-modal{
    width: 250px;
    background:rgba(22,44,96,0.9);
    position:absolute;
    // left:50%;
    // top:50%;
    padding:20px;
    border-radius: 10px;
    // transform: translate(-50%, -50%);
    z-index: 99999;
    .close{
        width: 20px;
        height: 20px;
        background:rgb(74, 176, 248);
        border-radius: 100%;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        font-weight: border;
        position:absolute;
        right:-5px;
        top:-5px;
        &:hover{
            background: rgba(74, 176, 248,.9);
        }
    }
    .btn-m {
        padding:0 15px;
        line-height: 32px;
        height:32px;
        color:#fff;
        border-radius: 6px;
        border:1px solid transparent;
        &.btn-restore {
            border-color: rgb(43,97,157);
            color:rgb(43,97,157);
        }
        &.btn-modify{
            background:rgb(43,97,157);
        }
    }
}
</style>