<template>
  <a-modal
    title="保存录像"
    :visible="visible"
    width="30%"
    :centered="true"
    :destroyOnClose="true"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <div class="text-red text-center m-b-sm">
      最大录制时长仅为2小时，超过时间自动停止录制
    </div>
    <div class="flex items-center m-b-sm">
      <div class="flex wd85 items-center relative">
        <span class="flex-s m-r-xs"
          ><span class="text-red m-r-xs">*</span>保存名称：</span
        >
        <a-input v-model="name" :maxLength="20" class="flex-one" />
      </div>
      <span class="m-l-sm">{{ name ? name.length : 0 }}/20</span>
    </div>
    <div class="flex items-center m-b-sm">
      <div class="flex wd85 items-center relative">
        <span class="flex-s m-r-xs">保存格式：</span>
        <a-select v-model="formatType" class="flex-one">
          <a-select-option value="MP4">MP4</a-select-option>
          <a-select-option value="flv">flv</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="flex items-center m-b-sm">
      <div class="flex wd85 items-center relative">
        <span class="flex-s m-r-xs">存储方式：</span>
        <a-select v-model="saveUrl" class="flex-one">
          <a-select-option :value="2">本地存储</a-select-option>
          <a-select-option :value="1">云端存储</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'SaveVideo',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmLoading: false,
      name: '新建录像',
      formatType: 'MP4',
      saveUrl: 2,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
    resetData() {
      this.name = '新建录像';
      this.formatType = 'MP4';
      this.saveUrl = 2;
    },
    handleOk() {
      let data = {
        fileType: this.formatType,
        videoName: this.name,
        storageMode: this.saveUrl,
      };
      this.$emit('sure', data);
      this.resetData();
    },
  },
};
</script>

<style lang="less" scoped>
.wd85 {
  width: 88%;
  .flex-s {
    width: 90px;
    text-align: right;
  }
}
</style>
