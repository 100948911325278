<template>
  <div class="three-flvPlayerWrap">
    <div id="playerContainer" @mousemove="videoClick"></div>
    <canvas id="canvas" width="617" height="400" style="display:none"></canvas>
    <video
      id="threevideo"
      loop
      muted
      crossOrigin="anonymous"
      playsinline
      style="display:none"
    >
      <!--<source src="textures/pano.webm">
            <source src="textures/pano.mp4">-->
    </video>
    <div class="canvasImg">
      <!-- <p class="close" @click="closeCanvas">X</p> -->
      <canvas id="canvas2" v-show="showCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import * as Three from 'three';
import FlvJs from 'flv.js';

export default {
  name: 'ThreeVideo',
  data() {
    return {
      flvPlayer: null,
      camera: null,
      scene: null,
      renderer: null,
      isUserInteracting: false,
      lon: 0,
      lat: 0,
      phi: 0,
      theta: 0,
      distance: 50,
      onPointerDownPointerX: 0,
      onPointerDownPointerY: 0,
      onPointerDownLon: 0,
      onPointerDownLat: 0,
      parentHeight: 0,
      parentWidth: 0,
      requestFrameStep: 0,
      showCanvas: false,
    };
  },
  mounted() {},
  methods: {
    getVideoElement() {
      let videoEl = document.getElementById('threevideo');
      if (videoEl) {
        return videoEl;
      }
    },
    //视频手动截图
    cut() {
      let canvas2 = document.getElementById('canvas2');
      let video = document.getElementById('videoEle');
      //  console.log(video,"video")
      //  console.log('视频尺寸：'+video.style.width+'*'+video.style.height);
      canvas2.width = video.offsetWidth;
      canvas2.height = video.offsetHeight;
      this.ctx = canvas2.getContext('2d');
      this.ctx.drawImage(video, 0, 0, canvas2.width, canvas2.height);
      var imgUrl = canvas2
        .toDataURL('image/png', 1)
        .replace('image/png', 'image/octet-stream');
      this.imgDown(imgUrl);
    },
    //图片下载
    imgDown(url) {
      var filename = '手动截图' + '.png';
      var save_link = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'a'
      );
      save_link.href = url;
      save_link.download = filename;
      var event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      save_link.dispatchEvent(event);
    },
    init() {
      let _this = this;
      var container, mesh;

      //console.info(Three);

      container = document.getElementById('playerContainer');
      _this.parentHeight = container.offsetHeight;
      _this.parentWidth = container.offsetWidth;

      _this.camera = new Three.PerspectiveCamera(
        75,
        _this.parentWidth / _this.parentHeight,
        1,
        1100
      );
      _this.camera.target = new Three.Vector3(0, 0, 0);
      _this.scene = new Three.Scene();
      var geometry = new Three.SphereBufferGeometry(500, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      geometry.scale(-1, 1, 1);

      var video = document.getElementById('threevideo');
      //video.play();
      var texture = new Three.VideoTexture(video);

      var material = new Three.MeshBasicMaterial({ map: texture });
      mesh = new Three.Mesh(geometry, material);
      _this.scene.add(mesh);
      _this.renderer = new Three.WebGLRenderer();
      _this.renderer.setPixelRatio(window.devicePixelRatio);
      //_this.renderer.setSize( window.innerWidth, window.innerHeight );
      _this.renderer.setSize(_this.parentWidth, _this.parentHeight);
      container.appendChild(_this.renderer.domElement);
      container.addEventListener('mousedown', _this.onDocumentMouseDown, false);
      container.addEventListener('mousemove', _this.onDocumentMouseMove, false);
      container.addEventListener('mouseup', _this.onDocumentMouseUp, false);
      container.addEventListener('wheel', _this.onDocumentMouseWheel, false);
      window.addEventListener('resize', _this.onWindowResize, false);
      container.addEventListener('resize', _this.onWindowResize, false);
    },
    onWindowResize() {
      let _this = this;
      _this.camera.aspect = _this.parentWidth / _this.parentHeight;
      _this.camera.updateProjectionMatrix();
      _this.renderer.setSize(_this.parentWidth, _this.parentHeight);
    },
    onDocumentMouseDown(event) {
      event.preventDefault();
      let _this = this;
      _this.isUserInteracting = true;
      _this.onPointerDownPointerX = event.clientX;
      _this.onPointerDownPointerY = event.clientY;
      _this.onPointerDownLon = _this.lon;
      _this.onPointerDownLat = _this.lat;
    },
    onDocumentMouseMove(event) {
      let _this = this;
      if (_this.isUserInteracting === true) {
        _this.lon =
          (_this.onPointerDownPointerX - event.clientX) * 0.1 +
          _this.onPointerDownLon;
        _this.lat =
          (_this.onPointerDownPointerY - event.clientY) * 0.1 +
          _this.onPointerDownLat;
      }
    },
    onDocumentMouseUp() {
      let _this = this;
      _this.isUserInteracting = false;
    },
    onDocumentMouseWheel(event) {
      let _this = this;
      _this.distance += event.deltaY * 0.05;
      _this.distance = Three.Math.clamp(_this.distance, 1, 50);
    },
    animate() {
      let _this = this;
      _this.requestFrameStep = window.requestAnimationFrame(_this.animate);
      _this.update();
    },
    update() {
      let _this = this;
      _this.lat = Math.max(-85, Math.min(85, _this.lat));
      _this.phi = Three.Math.degToRad(90 - _this.lat);
      _this.theta = Three.Math.degToRad(_this.lon);
      _this.camera.position.x =
        _this.distance * Math.sin(_this.phi) * Math.cos(_this.theta);
      _this.camera.position.y = _this.distance * Math.cos(_this.phi);
      _this.camera.position.z =
        _this.distance * Math.sin(_this.phi) * Math.sin(_this.theta);
      _this.camera.lookAt(_this.camera.target);
      _this.renderer.render(_this.scene, _this.camera);
    },

    flv_Play(sourceUrl) {
      //debugger
      this.flv_destroy();

      if (!FlvJs.isSupported()) {
        var check1 = !!window.MediaSource;
        var check2 = false;
        if (
          check1 &&
          window.MediaSource.isTypeSupported(
            'video/mp4; codecs="avc1.42E01E,mp4a.40.2"'
          )
        ) {
          check2 = true;
        }
        alert(
          '你的设备不支持flvjs。其中window.MediaSource：' +
            check1 +
            '。\n window.MediaSource.isTypeSupported(\'video/mp4; codecs="avc1.42E01E,mp4a.40.2"\'):' +
            check2
        );
      }

      let videoElement = document.getElementById('threevideo');
      if (typeof this.flvPlayer !== 'undefined') {
        if (this.flvPlayer != null) {
          this.flvPlayer.unload();
          this.flvPlayer.detachMediaElement();
          this.flvPlayer.destroy();
          this.flvPlayer = null;
        }
      }

      // 根据加载的数据，动态设置播放位置
      /*videoElement.addEventListener('progress', function() {
                    var range = 0;
                    var bf = this.buffered;
                    var time = this.currentTime;

                    while(!(bf.start(range) <= time && time <= bf.end(range))) {
                        range += 1;
                    }
                    this.currentTime = this.buffered.end(range) + 0.08;
                    //console.info('progress ',this.currentTime,this.buffered,range);
                });*/

      this.flvPlayer = FlvJs.createPlayer(
        {
          type: 'flv',
          isLive: true, // 直播
          hasVideo: true, // 视频
          hasAudio: false, // 音频
          //autoplay        : true, // 自动播放
          //controls        : true, // 控件
          url: sourceUrl, // 视频地址
          withCredentials: false, //

          cors: true,
          timestampBase: 0,
        },
        {
          /*enableWorker: false,
                    lazyLoadMaxDuration: 3 * 60,
                    seekType: 'range',*/

          //seekType: 'range', // 搜索
          enableWorker: true, // 分离线程加载数据
          enableStashBuffer: false, //禁用IO存储缓冲区,解决卡顿的根本问题
          //stashInitialSize: 1,//指示IO存储缓冲区初始大小,默认是 384kb*/
          //lazyLoad: true,// 数据缓存量足够情况下停止加载。默认打开，这里会阻止实时加载。
          //lazyLoadMaxDuration: 60,// 缓存秒数
          //lazyLoadRecoverDuration: 20,// 更新覆盖描述
          deferLoadAfterSourceOpen: false, // 切换浏览器选项卡之后停止加载，false取消限制
          //autoCleanupSourceBuffer: true,
          //autoCleanupMaxBackwardDuration: 60,
          //autoCleanupMinBackwardDuration: 30,
          //statisticsInfoReportInterval: 1,
          //fixAudioTimestampGap: false,
        }
      );
      this.flvPlayer.attachMediaElement(videoElement);

      //console.info(this.flvPlayer,this.flvPlayer.play,sourceUrl);

      this.flvPlayer.load();
      this.flvPlayer.play();

      /*setTimeout(()=>{
                    this.flvPlayer.play();
                },1000)*/

      /** */
      this.init();
      this.animate();
    },
    flv_destroy() {
      if (this.flvPlayer) {
        this.flvPlayer.pause();
        this.flvPlayer.unload();
        this.flvPlayer.detachMediaElement();
        this.flvPlayer.destroy();
        this.flvPlayer = null;

        //全景机展示重置
        //this.update();
        //this.camera = null;
        this.scene = null;
        this.renderer = null;
        document.getElementById('playerContainer').innerHTML = '';
        //console.info('this.requestFrameStep   ',this.requestFrameStep);
        if (this.requestFrameStep)
          window.cancelAnimationFrame(this.requestFrameStep);
      }
    },
    flv_fullScreen() {
      /*let ele = document.getElementById('threevideo');
                if (ele.requestFullscreen) {
                    ele.requestFullscreen();
                } else if (ele.mozRequestFullScreen) {
                    ele .mozRequestFullScreen();
                } else if (ele.webkitRequestFullScreen) {
                    ele .webkitRequestFullScreen();
                }

                let container = document.getElementById( 'playerContainer' );
                /!*container.style.height = window.offsetHeight;
                container.style.width  = window.innerWidth;*!/

                container.style = {
                    height : window.innerHeight,
                    width  : window.innerWidth,
                };*/
      //console.info('flv_fullScreen  ',ele.offsetHeight,container.offsetHeight);
      // this.onWindowResize();
    },
    videoClick(event) {
      //event.preventDefault();
      event.stopPropagation();
    },
  },
};
</script>

<style lang="less">
.three-flvPlayerWrap {
  width: 100%;
  height: 100%;
  #threevideo {
    width: 100%;
    height: 100%;
    background: #000;
  }

  #playerContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;

    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

/*video默认setting按钮*/
video::-internal-media-controls-overflow-button {
  display: none !important;
}
/*播放按钮*/
video::-webkit-media-controls-play-button {
  display: none !important;
}
/* 时间线*/
video::-webkit-media-controls-timeline {
  display: none !important;
}
/* 时间*/
video::-webkit-media-controls-current-time-display {
  display: none !important;
}
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}
/*video默认aduio音量按钮*/
video::-webkit-media-controls-mute-button {
  display: none !important;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}
video::-webkit-media-controls-volume-slider {
  display: none !important;
}
/* .canvasImg {
  .close {
    position: absolute;
    top: 117%;
    right: 10px;
  }
  #canvas2 {
    padding-top: 40px;
  }
} */
</style>
