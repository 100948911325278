<template>
  <div>
    <a-modal
      title="录像名称"
      width="20%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="flex items-center">
        <div class="flex-one">
          <a-input
            placeholder="请输入录像名称"
            v-model="cameralName"
            :maxLength="20"
          />
        </div>
        <span class="m-l-xs">{{ cameralName.length }}/20</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'VideoNameDialog',
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      cameralName: '',
      confirmLoading: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      e.preventDefault();
      setTimeout(() => {
        this.confirmLoading = false;
        this.$emit('closeDialog');
      }, 3000);
    },
    handleCancel() {
      console.log('Clicked cancel button');
      this.$emit('closeDialog');
    },
  },
};
</script>

<style scoped></style>
