<template>
  <div
    class="confirm-modal"
    :style="styleObj"
    v-show="visible"
  >
    <div class="confirm-modal-content">
      <div
        class="confirm-modal-info flex items-center text-white"
      >
        <a-icon type="exclamation-circle" class="m-r-xs" />
        {{ config.text || '拖动缩放地图可调整位置！' }}
      </div>
      <div
        class="confirm-modal-footer m-t-sm flex items-center justify-center"
      >
        <span
          v-if="config.isSubBtnShow"
          class="btn-m btn-modify m-r-sm  pointer"
          @click="submitHandle"
          >提交</span
        >
        <span
          class="btn-m btn-restore pointer"
          @click="cancelHandle"
          >取消</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModifyLat',
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    config: {
      type: Object,
      default: () => ({
        text: '拖动缩放地图可调整位置！',
        isSubBtnShow: true
      })
    }
  },
  data() {
    return {
      left: 0,
      top: 0
    }
  },
  computed: {
    styleObj() {
      return {
        left: `${this.left}px`,
        top: `${this.top - 120}px`,
        opacity: 1
      }
    }
  },
  methods: {
    //取消纠偏
    cancelHandle() {
      this.$emit('cancelConfirm')
    },
    //确定纠偏
    submitHandle() {
      this.$emit('submitHandle')
    },
    //设置位置
    setPos(x, y) {
      this.left = x
      this.top = y
    }
  }
}
</script>

<style lang="less" scoped>
.confirm-modal {
  width: fit-content;
  background: rgba(22, 44, 96, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 99999;
  .close {
    width: 20px;
    height: 20px;
    background: rgb(74, 176, 248);
    border-radius: 100%;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: border;
    position: absolute;
    right: -5px;
    top: -5px;
    &:hover {
      background: rgba(74, 176, 248, 0.9);
    }
  }
  .btn-m {
    padding: 0 15px;
    line-height: 32px;
    height: 32px;
    color: #fff;
    border-radius: 6px;
    border: 1px solid transparent;
    &.btn-restore {
      border-color: rgb(43, 97, 157);
      color: rgb(43, 97, 157);
    }
    &.btn-modify {
      background: rgb(43, 97, 157);
    }
  }
}
</style>
