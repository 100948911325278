<template>
  <a-modal
    title="抓拍"
    :visible="visible"
    width="24%"
    :centered="true"
    :destroyOnClose="true"
    :confirm-loading="confirmLoading"
    @cancel="hide"
    @ok="sureHandle"
  >
    <div class="flex items-center m-b-sm">
      <span class="title">保存名称：</span>
      <a-input v-model="name" class="flex-one" :maxLength="20" />
    </div>
    <div class="flex items-center">
      <span class="title">保存格式：</span>
      <a-select v-model="currentFomater" class="flex-one">
        <a-select-option
          v-for="item in formatList"
          :key="item.id"
          :value="item.value"
        >
          {{ item.value }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'SnapShotPic',
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      name: '保存名称',
      currentFomater: 'jpg',
      cutParams: {},
      formatList: [
        {
          id: 0,
          value: 'jpg',
        },
        {
          id: 1,
          value: 'jpeg',
        },
        {
          id: 2,
          value: 'png',
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    hide() {
      this.visible = false;
      this.resetData();
    },
    show(params) {
      this.cutParams = params;
      this.visible = true;
    },
    resetData() {
      this.name = '保存名称';
      this.currentFomater = 'jpg';
    },
    sureHandle() {
      let paramObj = {
        params: {
          snapshotName: this.name.trim(),
          fileType: this.currentFomater,
          cameraId: this.cutParams.cameraId,
          cameraNum: this.cutParams.cameraNum,
        },
        file: this.getFormData(this.cutParams.cutUrl),
      };
      if (!paramObj.params.snapshotName) {
        this.$message.error('保存名称不能为空！');
        return;
      }
      let objParams = {
        data: paramObj,
        instructionsDel: {
          module: '地图调阅',
          page: '视频调阅',
          feature: '手动抓拍',
          description: `手动抓拍: ${this.cutParams.cameraName}`,
        },
      };
      this.$api.snapshot.addScreenShout(objParams).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('上传截图成功！');
          this.resetData();
          this.hide();
        }
      });
      //this.hide();
    },
    /* dataURLtoFile(dataurl, filename = 'file') {
      let arr = dataurl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split('/')[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, { type: mime });
    }, */
    dataURItoBlob(base64Data) {
      let byteString;
      if (base64Data.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(base64Data.split(',')[1]);
      else byteString = unescape(base64Data.split(',')[1]);
      let mimeString = base64Data
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    /* getFormData(imageBase64) {
      let blob = this.dataURItoBlob(imageBase64); // 上一步中的函数
      let canvas = document.createElement('canvas');
      let dataURL = canvas.toDataURL('image/jpeg', 0.5);
      let fd = new FormData(document.forms[0]);
      fd.append('the_file', blob, 'image.png');
    }, */
    getFormData(dataUrl) {
      let blob = this.dataURItoBlob(dataUrl); // 上一步中的函数
      /*  let canvas = document.createElement('canvas');
      let dataURL = canvas.toDataURL('image/jpeg', 0.5); */
      let fd = new FormData();
      fd.append('file', blob, 'image.png');
      return fd;
      /* let data = new FormData();
      data.append('screenshot', dataUrl);
      return data; */
    },
  },
};
</script>

<style scoped></style>
