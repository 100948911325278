<template>
  <div class="home-main relative p-sm">
    <traffic-search v-show="!isException" />
    <!-- <alarm-component></alarm-component> -->
    <div class="absolute r10 z-999 flex" v-show="showButton && $g.showLong">
      <analysis-address-button class="m-r-sm" />
      <analysis-lat-button />
    </div>
    <div class="absolute r10 z-999" v-show="!showButton">
      <a-button type="primary" @click="$router.go(-1)">退出全屏</a-button>
    </div>
    <traffic-amap
      ref="amapIns"
      @amapComplete="amapCompleteCallback"
      @amapClick="amapClickCallback"
      @amapChanged="mapChanged"
      :base-top-adcodes="regionCode"
      :base-top-adcodes-name="regionName"
      :load-cur-area="true"
      v-if="!isQF"
      class="traffic-map-con"
    >
    </traffic-amap>
    <QFMap
      ref="qfMap"
      @loaded="loadedHandle"
      @amapClick="amapClickCallback"
      @amapChanged="mapChanged"
      v-if="isQF"
      class="traffic-map-con"
    ></QFMap>
    <traffic-bottom v-show="!isException"/>

    <map-layer
      :class="[`absolute r10 ${$g.regionCode === 500000 ? 't20' : 'b20'}`]"
      @selectLayer="selectLayerHandle"
      v-show="!isException"
    >
      <div
        :class="[
          `absolute pic-color-wrap ${
            $g.regionCode === 500000 ? 't-chong' : 't-other'
          }`,
        ]"
      >
        <div class="pic-color flex full-width items-center">
          <span class="text-green flex-s">通畅</span>
          <div class="progress-pic flex-one m-l-sm m-r-sm flex items-center">
            <span class="flex-one bg-green full-height"></span>
            <span class="flex-one bg-yellow full-height"></span>
            <span class="flex-one bg-orange full-height"></span>
            <span class="flex-one bg-red full-height"></span>
          </div>
          <span class="text-red flex-s">拥堵</span>
        </div>
      </div>
    </map-layer>
    <a-select
      v-model="mapValue"
      @change="handleChangeMap"
      class="absolute r0 b50"
      v-if="false"
    >
      <!-- <a-select-option value="base">
        标准图层
      </a-select-option> -->
      <a-select-option value="trafficLayer">
        实时交通图层
      </a-select-option>
      <a-select-option value="roadNet">
        路网图层
      </a-select-option>
      <a-select-option value="satellite" v-if="!isQF">
        卫星图层
      </a-select-option>
    </a-select>
    <!--  <video-dialog
      v-if="showVideo"
      @closeDialog="showVideo = false"
    ></video-dialog> -->

    <!-- 定时录像按钮 -->
    <div
      v-if="hasPermission(1092101112)"
      class="timing-recording-btn btn flex-center"
      title="定时录像"
      @click="$router.push('/timing-recording')"
      v-show="!isException && $g.isDepart"
    />
  </div>
</template>

<script>
import TrafficSearch from './TrafficSearch';
import TrafficAmap from '@/components/TrafficAmap';
import TrafficBottom from './TrafficBottom';
// import VideoDialog from '@/components/VideoDialog';
// import AlarmComponent from './AlarmComponent';
import { mapMutations, mapState, mapActions } from 'vuex';
import { refreshAmapMarks, refreshOrganizationAmapMarks } from '@/tools';
import AnalysisAddressButton from './AnalysisAddressButton';
import AnalysisLatButton from './AnalysisLatButton';
import QFMap from '@/components/QFMap';
import MapLayer from './MapLayer';
import {CODE_OK} from '@/request/config_code'
export default {
  name: 'HomeMain',
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    isException: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    TrafficAmap,
    TrafficSearch,
    TrafficBottom,
    // VideoDialog,
    // AlarmComponent,
    AnalysisAddressButton,
    AnalysisLatButton,
    QFMap,
    MapLayer,
  },
  data() {
    return {
      regionName: '重庆',
      regionCode: window.g.regionCode,
      showVideo: false,
      isFirstRender: true,
      // mapValue: 'base',
      mapValue: 'trafficLayer',
      isQF: false,
    };
  },
  computed: {
    ...mapState({
      selectNode: (state) => state.home.selectNode,
      type: (state) => state.home.type,
      sharingPointCamera:(state) => state.sharing.sharingPointCamera,
    }),
  },
  watch: {
    isException(nv, ov) {
      if (nv && nv !== ov) {
        if (window.g.qfMap) {
          this.isQF = true;
        } else {
          this.isQF = false;
        }
        // this.setAmapIns(this.$refs.amapIns);
        this.setMapInstance();
      }
    },
  },
  mounted() {
    if (window.g.qfMap) {
      this.isQF = true;
    } else {
      this.isQF = false;
    }
    // this.setAmapIns(this.$refs.amapIns);
    this.setMapInstance();
  },
  methods: {
    ...mapActions(['getSharingCameras']),
    ...mapMutations(['setAmapIns','setSharingPointCamera']),
    loadedHandle() {
      if (this.isException) {
        //设置地图层级
        // console.log('hah', this.$refs.qfMap);
        if (this.$refs.qfMap.getInitZoom() < 5) {
          this.$refs.qfMap.setInitZoom();
        }

        // this.$refs.qfMap.mapInstance.getView().setZoom(8);
      }
    },
    setMapInstance() {
      this.$nextTick(() => {
        let amp = null;
        if (window.g.qfMap) {
          amp = this.$refs.qfMap;
        } else {
          amp = this.$refs.amapIns;
        }
        this.setAmapIns(amp);
      });
    },
    mapChanged() {
      /*  if (this.isFirstRender) {
        this.$refs.amapIns.mapInstance.setZoom(5);
        this.$refs.amapIns.mapInstance.setCenter([106.550464, 29.563761]);
        this.isFirstRender = false;
        return;
      } */
      // console.error(this.selectNode);
      // console.error('是否变化', this.type, this.selectNode);
      if (this.isException) {
        refreshOrganizationAmapMarks(this.data);
      } else {
        console.error('error',this.selectNode)
        refreshAmapMarks(this.type, this.selectNode);
        if(this.$g.isDepart) {
          this.getSharingCameras().then(res=>{
              if(res.code === CODE_OK){
                const { list } = res.data
                // let sharingPointCamera = JSON.parse(JSON.stringify(this.sharingPointCamera))
                  // sharingPointCamera[folderId] = this.bindPoint(list)
                  let sharingPointCamera = this.bindPoint(list)
                  // const sharingPointFormat = this.formatPoint(sharingPointCamera)
                  this.setSharingPointCamera(sharingPointCamera)
                  this.$nextTick(() => {
                      this.$refs.qfMap.setSharePoint(sharingPointCamera);
                  });
              }
          })
        }
      }
    },
    bindPoint(data) {
      if(!data||data.length<0){
        return []
      }
      return data.map(item=>{
              item.point = [item.longitude,item.latitude]
              return item;
            });
    },
    formatPoint(sharingPointCamera){
      if(!sharingPointCamera || JSON.stringify(sharingPointCamera) === '{}') {
        return []
      }
      let arr = []
      for(let key in sharingPointCamera) {
        arr = arr.concat(sharingPointCamera[key])
      }
      return arr;
    },
    amapCompleteCallback() {
      /*  this.regionCode = 500000;
      this.regionName = '重庆';
      this.currentMapRegionCode = 500000; */
      console.log('complete');
      this.regionCode = window.g.regionCode;
      this.regionName = window.g.title;
      this.currentMapRegionCode = window.g.regionCode;

      // refreshAmapMarks(this.type);
    },
    amapClickCallback() {},
    handleChangeMap(value) {
      this.mapValue = value;
      /* this.$refs.amapIns.checkGoogleMapType(value); */
      if (!this.isQF) {
        this.$refs.amapIns.checkGoogleMapType(value);
      } else {
        this.$refs.qfMap.checkGoogleMapType(value);
      }
    },
    selectLayerHandle(layer) {
      this.handleChangeMap(layer);
    },
  },
  destroyed() {
  /*   if(window.g.qfMap) {
      this.$refs.qfMap && this.$refs.qfMap.destroyMap();
    }else{ */
      this.$refs.amapIns && this.$refs.amapIns.destroyMap();
   /*  } */
    
  },
};
</script>

<style scoped lang="less">
.home-main {
  background: linear-gradient(
    0deg,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );

  height: 100%;
  color: #fff;
}
.b50 {
  bottom: 140px;
  right: 10px;
}
.b20 {
  bottom: 20px;
  right: 10px;
}
// .home-main >>> .ant-select-selection {
//   background: #08224a;
// }
.pic-color-wrap {
  right: 0;
  width: 150%;
  min-width: 160px;
}
.pic-color {
  background: rgba(32, 58, 102, 0.5);
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}
.text-green {
  color: rgba(68, 214, 126, 1);
}
.text-red {
  color: rgba(231, 56, 43, 1);
}
.bg-green {
  background: rgba(68, 214, 126, 1);
  margin-right: 2px;
}
.bg-yellow {
  background: rgba(231, 193, 43, 1);
  margin-right: 2px;
}
.bg-orange {
  background: rgba(231, 118, 43, 1);
  margin-right: 2px;
}
.bg-red {
  background: rgba(231, 56, 43, 1);
}
.progress-pic {
  height: 6px;
  border-radius: 10px;
  overflow: hidden;
}
.r20 {
  right: 20px;
}
.b20 {
  bottom: 20px;
}
.t20 {
  top: 20px;
}
.t-other {
  top: -120%;
}
.t-chong {
  bottom: -110%;
}
.traffic-map-con {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.home-main {
  .timing-recording-btn {
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    &:active::before {
      height: 12px;
      opacity: 0;
      width: 12px;
    }
    &::before {
      border: 1px solid #aaa;
      border-radius: 50%;
      content: '';
      display: block;
      height: 100%;
      opacity: 1;
      transition: height 0.1s, width 0.1s, opacity 0.3s;
      width: 100%;
    }
    &::after {
      background-color: #d9001b;
      border-radius: 50%;
      content: '';
      display: block;
      height: 10px;
      left: 10px;
      position: absolute;
      top: 10px;
      width: 10px;
    }
  }
}
</style>
