<template>
  <div class="map-layer relative">
    <transition name="animate"> <slot v-if="curId === 0"></slot></transition>
    <ul class="flex items-center map-layer-inner">
      <li
        v-for="item in mapList"
        :key="item.id"
        :class="[
          `flex-one text-center pointer ${item.id === curId ? 'current' : ''}`,
        ]"
        @click="selectItem(item)"
      >
        <i class="ve-m" :class="item.iconClass"></i>
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MapLayer',
  data() {
    return {
      mapList: [
        {
          id: 0,
          iconClass: 'traffic',
          name: '路况',
          layer: 'trafficLayer',
        },
        {
          id: 1,
          iconClass: 'satellite',
          name: '卫星',
          layer: 'satellite',
        },
        // {
        //   id: 3,
        //   iconClass: 'fullScreenMap',
        //   src: require('@/assets/home/fullScreenMap.png'),
        //   name: '全屏',
        //   layer: 'map',
        // },
        /* {
          id: 2,
          src: require('@/assets/home/road-layer.png'),
          name: '路网',
          layer: 'roadNet',
        }, */
      ],
      curId: 0,
      // curImg: require('@/assets/home/traffic-layer.png'),
      showImg: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    /* if (this.$g.qfMap) {
      this.mapList = this.mapList.filter((item) => item.id !== 2);
    } */
  },
  methods: {
    selectItem(item) {
      console.log(item,'item点击')
      this.curId = this.curId === item.id ? -1 : item.id;
      this.$emit('selectLayer', this.curId != -1 ? item.layer : 'base');
      if(item.layer == 'map'){
        this.$router.push({ path: '/map-com' });
      }
    },
  },
};
</script>

<style scoped>
.map-layer {
  width: 20%;
  max-width: 140px;
  border-radius: 6px;
  line-height: 28px;
  color: rgba(122, 164, 236, 0.6);
  right: 2.5rem !important;
}
.map-layer-inner {
  background: rgba(32, 58, 102, 1);
  width: 180px;

}
.map-layer-inner li.current {
  background: rgba(68, 99, 152, 0.5);
  color: rgba(122, 164, 236, 1);
}
.traffic,
li.current .traffic {
  display: inline-block;
  background: url('~@/assets/home/traffic.png') no-repeat;
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
}
li.current .traffic {
  background: url('~@/assets/home/traffic-on.png') no-repeat;
  background-size: 100% 100%;
}
.satellite, .fullScreenMap,
li.current .satellite {
  display: inline-block;
  background: url('~@/assets/home/satellite.png') no-repeat;
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
}

li.current .satellite {
  background: url('~@/assets/home/satellite-on.png') no-repeat;
  background-size: 100% 100%;
}
.fullScreenMap {
  background: url('~@/assets/home/fullScreenMap.png') no-repeat;
  background-size: 100% 100%;
}
.animate-enter,
.animate-leave-to {
  opacity: 0;
}
.animate-enter-active,
.animate-leave-active {
  transition: all 0.4s;
}
</style>
