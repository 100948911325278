var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qf-map_wrap"},[_c('div',{staticClass:"qf-map-box",attrs:{"id":_vm.id}}),_c('video-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag",value:(_vm.dragHandle),expression:"dragHandle"}],ref:"amapPlayer",attrs:{"cameraId":_vm.videoCameraId,"visible":_vm.cameraMarkVisable},on:{"update:visible":function($event){_vm.cameraMarkVisable=$event}}}),_vm._m(0),(_vm.samLonAndLat.length)?_c('div',{ref:"ulDom",staticClass:"ul-kh scroll"},[_c('ul',_vm._l((_vm.samLonAndLat),function(item){return _c('li',{key:item.cameraId,on:{"click":function($event){return _vm.openVideo(item)}}},[_vm._v(_vm._s(item.khPile))])}),0)]):_vm._e(),_c('div',{staticClass:"gd-map",attrs:{"id":"gdMap"}}),_c('drag',{ref:"dragMarkerGhost",attrs:{"id":"dragMarkerGhost","transfer-data":_vm.chosedMarkData,"draggable":true,"image":[
        'zsy_qjsxj_00001',
        'zsy_qjsxj_00002',
        'zsy_qjsxj_00003',
        'zsy_qjsxj_hall_01',
        'zsy_qjsxj_hall_02' ].indexOf(_vm.chosedMarkData.cameraNum) != -1
        ? _vm.cameraPanoramaImgUrl
        : _vm.cameraImgUrl[_vm.chosedMarkData.classify]},on:{"dragstart":_vm.dragMarkerGhostDragstart,"dragend":_vm.dragMarkerGhostDragend}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ol-popup",attrs:{"id":"popup"}},[_c('div',{attrs:{"id":"popup-content"}})])}]

export { render, staticRenderFns }