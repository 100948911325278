<template>
  <transition name="animate">
    <div v-if="visible" class="analysis-dialog p-sm m-t-sm relative">
      <span class="flex flex-end m-b-xs pointer" @click="closeHandle"
        >收起</span
      >
      <template v-if="mode === 'address'">
        <div class="flex items-center justify-center">
          <a-input
            placeholder="请输入地名和设备名称"
            @change="changeAddressValue"
          />
          <a-button
            type="primary"
            class="m-l-sm m-r-sm"
            @click="searchAddressHandle"
            >搜索</a-button
          >
          <a-popover placement="bottom">
            <template slot="content">
              <p>
                支持单个地名和设备名称
              </p>
            </template>
            <i class="icon-help m-l-sm pointer"></i>
          </a-popover>
        </div>
      </template>
      <template v-if="mode === 'lat'">
        <div class="flex items-center justify-center">
          <a-input placeholder="请输入经纬度" @change="changeLat" />
          <a-button type="primary" class="m-l-sm" @click="searchAddressHandle"
            >搜索</a-button
          >
          <a-popover placement="bottom">
            <template slot="content">
              <p>
                支持单个经纬度
              </p>
            </template>
            <i class="icon-help m-l-sm pointer"></i>
          </a-popover>
        </div>
      </template>
      <div class="text-lightblue m-t-sm">解析结果：{{ latResult }}</div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { refreshAmapMarks } from '@/tools';
export default {
  name: 'AnalysisDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      input: '',
    };
  },
  computed: {
    ...mapState({
      selectNode: (state) => state.home.selectNode,
      type: (state) => state.home.type,
      latResult: (state) => state.home.latResult,
      amapIns: (state) => state.home.amapIns,
    }),
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue !== oldValue) {
        /* this.setLatResult('');
        this.setAddressValue('');
        this.setLat(''); */
        this.resetData();
        this.amapIns.removeCurrentPosMarker();
      }
    },
  },
  methods: {
    ...mapMutations(['setAddressValue', 'setLat', 'setLatResult']),
    closeHandle() {
      this.$emit('closeDialog');
    },
    searchAddressHandle() {
      refreshAmapMarks(this.type, this.selectNode, true);
    },
    changeAddressValue(e) {
      /*     let obj = {
        type: 'address',
        value,
      }; */
      this.resetData();
      this.setAddressValue(e.target.value);
    },
    changeLat(e) {
      /*   let obj = {
        type: 'lat',
        value,
      }; */
      this.resetData();
      this.setLat(e.target.value);
    },
    resetData() {
      this.setLatResult('');
      this.setAddressValue('');
      this.setLat('');
    },
  },
};
</script>

<style scoped>
.analysis-dialog {
  width: 300px;
  background: linear-gradient(
    0deg,
    rgba(15, 40, 61, 0.9),
    rgba(46, 86, 142, 0.9)
  );
  border-radius: 6px;
}
.analysis-dialog::before {
  content: '';
  border: 8px solid transparent;
  border-bottom-color: rgba(46, 86, 142, 0.5);
  right: 40px;
  top: -15px;
  position: absolute;
}
.animate-enter-active,
.animate-leave-active {
  transition: opacity 0.5s;
}
.animate-enter,
.animate-leave-to {
  opacity: 0;
}
</style>
