<template>
  <div class="relative">
    <div
      class="select pointer flex items-center justify-center"
      @click="selectHandle"
    >
      <span>{{ curProvince['title'] }}</span
      ><a-icon type="down" class="m-l-xs down-icon" />
    </div>
    <div class="drop-down absolute p-sm" v-show="visible">
      <ul class="flex items-center flex-wrap">
        <li
          v-for="item in dataList"
          :key="item.id"
          class="pointer p-xs m-r-xs m-b-xs"
          :class="{ current: item.id === curProvince.id }"
          @click="selectArea(item)"
        >
          {{ item.title }}({{ item.total }})
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
const GLOBAL_CONTRY = { title: '全国', id: '', total: 0 };
export default {
  name: 'ProvinceSelect',
  props: {
    regionCode: {
      type: String,
      default: 'aa',
    },
  },
  data() {
    return {
      visible: false,
      dataList: [],
      curProvince: GLOBAL_CONTRY,
    };
  },
  computed: {},
  watch: {
    /* regionCode(nv) {
      if (!nv) {
        this.curProvince = GLOBAL_CONTRY;
      }
    }, */
  },
  created() {
    this.getProvinceDepart();
  },
  methods: {
    selectHandle() {
      this.visible = !this.visible;
    },
    getProvinceDepart() {
      let params = {
        type: 1,
      };
      this.$api.home.getCameraByRegion(params).then((res) => {
        if (res.code === CODE_OK) {
          this.loading = false;
          let data = res.data.map((item) => ({
            ...item,
            title: item.regionName,
            id: item.regionCode,
            total: item.total,
          }));
          /* let total = data.reduce((total, cur) => {
            return (total += cur.total);
          }, 0); */
          // this.dataList = [{ title: '全国', id: '', total }, ...data];
          // this.curProvince = this.dataList[0];
          this.dataList = data;
          this.curProvince = GLOBAL_CONTRY;
          //地图联动
          // this.refreshMapMarks();
        }
      });
    },
    selectArea(item) {
      this.curProvince = this.curProvince.id === item.id ? GLOBAL_CONTRY : item;
      this.visible = false;
      this.$emit('handleProvince', this.curProvince.id);
    },
  },
};
</script>

<style scoped>
.select {
  border: 1px solid rgba(76, 101, 138, 1);
  padding: 2px 10px;
  height: 32px;
  background: rgba(22, 39, 65, 1);
  color: rgba(255, 255, 255, 1);
}
.drop-down {
  left: 0;
  top: 100%;
  width: 500px;
  background: rgb(2, 16, 65);
  color: #fff;
}
.down-icon {
  color: rgba(150, 185, 228, 1);
}
.current {
  background: rgb(134, 158, 243);
}
</style>
